import React from "react";

function MedisuiteSiteFooter() {
  return (
    <>
      <footer id="footer" className="bg-neutral-50 text-gray-800">
        <div className="p-8 sm:px-12 md:px-24 lg:px-32 xl:px-16 max-w-[1300px] mx-auto">
          <div className="max-w-md mx-auto md:max-w-none md:grid md:grid-cols-3 md:gap-8">
            <div className="mb-8 md:mb-0 flex flex-col">
              <h2 className="text-xl font-semibold mb-4">Ein Angebot von:</h2>
              <div className="space-y-2">
                <div id="address-block" className="flex items-start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-[#abbe33] mt-1 mr-3"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
                  </svg>
                  <div>
                    <p className="font-medium">Scheitlin Papier AG</p>
                    <p>Hummelweg 17</p>
                    <p>9244 Niederuzwil</p>
                  </div>
                </div>
                <div id="phone-info" className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-[#abbe33] mr-3"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M6.62 10.79a15.053 15.053 0 006.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1v3.5c0 .55-.45 1-1 1C10.07 22 2 13.93 2 4.5c0-.55.45-1 1-1H6.5c.55 0 1 .45 1 1 0 1.24.2 2.45.57 3.57.12.35.03.74-.24 1.02l-2.2 2.2z" />
                  </svg>
                  <p>T 071 292 30 70</p>
                </div>
                <div id="fax-info" className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-[#abbe33] mr-3"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M19 7h-1V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v3H5c-1.1 0-2 .9-2 2v9c0 1.1.9 2 2 2h1v1c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-1h1c1.1 0 2-.9 2-2v-9c0-1.1-.9-2-2-2zm-9 0V4h4v3H10zm7 12H7v-6h10v6zm2-8H5v-3h14v3z" />
                  </svg>
                  <p>F 071 292 30 71</p>
                </div>
                <div id="email-info" className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-[#abbe33] mr-3"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                  </svg>
                  <a
                    href="mailto:info@scheitlin-medical.ch"
                    className="hover:underline"
                  >
                    info@scheitlin-medical.ch
                  </a>
                </div>
                <div id="opening-times" className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-[#abbe33] mr-3"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-.5-13h1v6l5.25 3.15-.75 1.23L11.5 13V7z" />
                  </svg>
                  <p>Mo-Fr 07:30 - 12:00 / 13:00 - 17:00</p>
                </div>
              </div>
            </div>

            <div className="mb-8 flex flex-col">
              <h2 className="text-xl font-semibold mb-4">Mitglied von:</h2>
              <div className="space-y-4">
                <div className="flex items-center">
                  <div className="w-10 h-10 p-1 bg-[#a62743] rounded-lg flex items-center justify-center mr-3">
                    <img
                      src="/home/images/medisuite/groups/gvs-group.png"
                      alt="GVS Group"
                    />
                  </div>
                  <div>
                    <h3 className="font-semibold">GVS Group</h3>
                    <p className="text-sm text-gray-600">
                      Einkaufsgenossenschaft
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="w-10 h-10 p-1 bg-[#003a6e] rounded-lg flex items-center justify-center mr-3">
                    <img
                      src="/home/images/medisuite/groups/dhys-group.svg"
                      alt="DHYS International Group"
                    />
                  </div>
                  <div>
                    <h3 className="font-semibold">DHYS International Group</h3>
                    <p className="text-sm text-gray-600">
                      Handelsverband für professionelle Hygiene
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-8 md:mb-0">
              <h2 className="text-xl font-semibold mb-4">Über uns:</h2>
              <div className="grid grid-cols-2 gap-4">
                <ul className="space-y-2">
                  <li>
                    <a
                      href="https://scheitlin-medical.ch/firma/"
                      className="hover:underline transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Unternehmen
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.scheitlin-papier.ch/firma/team.html"
                      className="hover:underline transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Team
                    </a>
                  </li>
                </ul>
                <ul className="space-y-2">
                  <li>
                    <a
                      href="https://www.scheitlin-papier.ch/kontakt/impressum.html"
                      className="hover:underline transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Impressum
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.scheitlin-papier.ch/kontakt/agb.html"
                      className="hover:underline transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      AGB
                    </a>
                  </li>
                </ul>
              </div>

              <h2 className="text-xl font-semibold mb-4 mt-8">
                Unsere Websites:
              </h2>
              <ul className="space-y-2">
                <li>
                  <a
                    href="https://scheitlin-medical.ch"
                    className="hover:underline transition-colors"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Scheitlin-Medical.ch
                  </a>
                </li>
                <li>
                  <a
                    href="https://scheitlin-papier.ch"
                    className="hover:underline transition-colors"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Scheitlin-Papier.ch
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="border-t p-6">
        <div className="flex flex-wrap justify-center text-sm text-gray-600 mb-4">
          <span className="mx-2 hover:underline cursor-pointer">
            Cookie-Zustimmung verwalten
          </span>
        </div>

        <p className="text-center text-sm text-gray-500">
          © Scheitlin&nbsp;Papier&nbsp;AG. Alle&nbsp;Rechte&nbsp;vorbehalten.
        </p>
      </div>
    </>
  );
}

export default MedisuiteSiteFooter;
