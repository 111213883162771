import React from "react";

export default function MedisuiteResidentIllustration() {
  return (
    <div className="bg-white shadow-md overflow-hidden rounded-t-lg">
      <header
        id="header"
        className="bg-[#C8D65D] flex justify-between items-center"
      >
        <h1 className="text-white font-bold text-lg p-4">
          Bewohner hinzufügen
        </h1>
        <button className="text-white">
          <i className="fa-solid fa-ellipsis-vertical"></i>
        </button>
      </header>
      <div id="add-resident-form" className="bg-neutral-100">
        <div className="p-4">
          <div className="mb-4">
            <label
              htmlFor="department"
              className="block text-sm font-medium text-neutral-700 mb-1"
            >
              Bitte wählen Sie eine Wohngruppe
            </label>
            <select
              id="department"
              className="w-full p-2 border border-neutral-300 rounded-md shadow-sm"
              defaultValue={"Wohngruppe A"}
              disabled
            >
              <option disabled="" value="Wohngruppe A">
                — Wohngruppe A
              </option>
            </select>
          </div>
          <div className="mb-4">
            <div className="grid grid-cols-2 gap-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-neutral-700 mb-1"
                >
                  Vorname (*)
                </label>
                <input
                  type="text"
                  id="first-name"
                  defaultValue={"Karin"}
                  className="w-full p-2 border border-neutral-300 rounded-md shadow-sm"
                  required=""
                  disabled
                />
              </div>
              <div>
                <label
                  htmlFor="lastname-name"
                  className="block text-sm font-medium text-neutral-700 mb-1"
                >
                  Nachname (*)
                </label>
                <input
                  type="text"
                  id="lastname-name"
                  defaultValue={"Müller"}
                  className="w-full p-2 border border-neutral-300 rounded-md shadow-sm"
                  required=""
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="birthday"
              className="block text-sm font-medium text-neutral-700 mb-1"
            >
              Geburtsdatum (*)
            </label>
            <input
              type="date"
              id="birthday"
              defaultValue={"1965-01-01"}
              className="w-full p-2 border border-neutral-300 rounded-md shadow-sm"
              required=""
              disabled
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="department"
              className="block text-sm font-medium text-neutral-700 mb-1"
            >
              Krankenkasse
            </label>
            <select
              id="department"
              className="w-full p-2 border border-neutral-300 rounded-md shadow-sm"
              defaultValue={"swica"}
              disabled
            >
              <option disabled="" value={"swica"}>
                — Swica
              </option>
            </select>
          </div>
          <button
            type="submit"
            className="w-full bg-neutral-700 text-white py-2 px-4 hover:bg-neutral-600"
          >
            Bewohner erfassen
          </button>
        </div>
      </div>
    </div>
  );
}
