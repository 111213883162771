export const SIGNUP_ROUTES = [
  "/signup/contact",
  "/signup/business",
  "/signup/departments",
  "/signup/employees",
  "/signup/summary",
  "/signup/success",
];

export const SIGNUP_APPROVAL_ROUTES = [
  "/signup-approval/overview",
  "/signup-approval/mapping",
  "/signup-approval/company-type",
  "/signup-approval/representative",
  "/signup-approval/favorits",
  "/signup-approval/logo",
  "/signup-approval/final",
  "/signup-approval/success",
];

export const DEFAULT_RIGHTS = [
  {
    id: "can_edit_residents",
    label: "Bewohner erfassen/bearbeiten",
    default: true,
  },
  // ... other rights
];
