import React, { useEffect } from "react";
import SignupHeader from "../signup/SignupHeader";
import SignupProgress from "../signup/SignupProgress";
import SignupDescription from "../signup/SignupDescription";
import { useSignupNavigation } from "../../hooks/useSignupNavigation";

export default function SignupPageLayout({
  title,
  step,
  totalSteps,
  progress,
  description,
  children,
  footer,
  parseHtml = false,
  showBackButton = true,
  showHeader = true,
  explainTooltip,
}) {
  const { goToPrevious } = useSignupNavigation();

  // Add event listener to handle viewport height on mobile
  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight * 0.01;
      // Get stored initial vh or set it if not exists
      const storedInitialVh = localStorage.getItem("initialViewportHeight");
      if (!storedInitialVh) {
        localStorage.setItem("initialViewportHeight", vh);
      }
      // Use the larger of current vh or stored initial vh
      const finalVh = Math.max(vh, storedInitialVh || vh);
      document.documentElement.style.setProperty("--vh", `${finalVh}px`);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    window.addEventListener("orientationchange", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
      window.removeEventListener("orientationchange", updateHeight);
    };
  }, []);

  return (
    <div className="min-[450px]:bg-[#f3f4f6] min-[450px]:min-h-screen min-[450px]:flex min-[450px]:items-center">
      <div
        className="bg-white min-[450px]:shadow-xl min-[450px]:min-w-[450px] flex flex-col max-w-md mx-auto relative"
        style={{ height: "calc(var(--vh, 1vh) * 100)", maxHeight: "800px" }}
      >
        {showHeader && (
          <SignupHeader
            title={title}
            onBack={goToPrevious}
            showBackButton={showBackButton}
            explainTooltip={explainTooltip}
          />
        )}
        <SignupProgress
          step={step}
          totalSteps={totalSteps}
          percentage={progress}
        />
        <SignupDescription description={description} parseHtml={parseHtml} />
        <div className="flex-1 p-4 space-y-4 overflow-y-auto">{children}</div>
        <div className="sticky bottom-0 w-full">{footer}</div>
      </div>
    </div>
  );
}
