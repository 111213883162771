import React, { useState, useEffect } from "react";
import { useSignup } from "../../../contexts/SignupContext";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";
import { cityFromZip } from "swiss-zipcodes";

export default function SignupBusiness() {
  const { signupData, updateSignupData } = useSignup();
  const { goToNext, _ } = useSignupNavigation();
  const [formData, setFormData] = useState(signupData.institution);
  const [isValid, setIsValid] = useState(false);
  const [progress, setProgress] = useState(20);
  const [suggestedCities, setSuggestedCities] = useState([]);
  const [showCityDropdown, setShowCityDropdown] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "zip") {
      // Only allow digits and limit to 4 digits
      const numericValue = value.replace(/\D/g, "").slice(0, 4);
      setFormData((prev) => ({
        ...prev,
        [name]: numericValue,
      }));

      // Auto-fill city when zip is 4 digits
      if (numericValue.length === 4) {
        const cities = cityFromZip(numericValue);
        if (cities && cities.length > 0) {
          setSuggestedCities(cities);
          setShowCityDropdown(true);
          setFormData((prev) => ({
            ...prev,
            zip: numericValue,
            city: cities[0], // Use the first city in the list
          }));
        } else {
          setSuggestedCities([]);
          setShowCityDropdown(false);
        }
      }
      return;
    }

    if (name === "city") {
      setShowCityDropdown(true);
      const cities = suggestedCities.filter((city) =>
        city.toLowerCase().includes(value.toLowerCase()),
      );
      setSuggestedCities(cities);
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const selectCity = (city) => {
    setFormData((prev) => ({
      ...prev,
      city,
    }));
    setShowCityDropdown(false);
  };

  // Add this to close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = () => setShowCityDropdown(false);
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleBlur = (e) => {
    const requiredFields = ["name", "street", "zip", "city"];
    const filledFields = requiredFields.filter((field) =>
      formData[field]?.trim(),
    );
    const fieldProgress = filledFields.length * 5;
    const baseProgress = 20;
    setProgress(baseProgress + fieldProgress);
  };

  useEffect(() => {
    const { name, street, zip, city } = formData;
    setIsValid(name && street && zip && city);
  }, [formData]);

  useEffect(() => {
    const nameInput = document.getElementById("name");
    if (nameInput) {
      nameInput.focus();
    }
  }, []);

  const handleSubmit = () => {
    if (isValid) {
      updateSignupData("institution", formData);
      goToNext();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && isValid) {
      handleSubmit();
    }
  };

  return (
    <SignupPageLayout
      title="Adresse"
      step={2}
      totalSteps={5}
      progress={progress}
      description="Bitte erfassen Sie die Adresse Ihrer Einrichtung."
      explainTooltip="Sollten Sie mehrere Standorte haben, geben Sie bitte die Adresse des Hauptstandorts ein."
      footer={<SignupFooter isValid={isValid} onSubmit={handleSubmit} />}
    >
      <div className="space-y-6">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Institution <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            onBlur={handleBlur}
            className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
            placeholder="Name Ihrer Institution"
          />
        </div>

        <div className="flex space-x-4">
          <div className="flex-grow">
            <label
              htmlFor="street"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Strasse <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="street"
              name="street"
              value={formData.street}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
              placeholder="Hauptstrasse"
            />
          </div>
          <div className="w-1/4">
            <label
              htmlFor="streetNo"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Nr.
            </label>
            <input
              type="text"
              id="streetNo"
              name="streetNo"
              value={formData.streetNo}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
              placeholder="10"
            />
          </div>
        </div>

        <div className="flex space-x-4">
          <div className="w-1/2">
            <label
              htmlFor="zip"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              PLZ <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="zip"
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
              placeholder="3001"
            />
          </div>
          <div className="w-1/2 relative">
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Ort <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
              placeholder="Bern"
              onClick={(e) => {
                e.stopPropagation();
                setShowCityDropdown(true);
              }}
            />
            {showCityDropdown && suggestedCities.length > 0 && (
              <div
                className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg"
                onClick={(e) => e.stopPropagation()}
              >
                {suggestedCities.map((city, index) => (
                  <div
                    key={index}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => selectCity(city)}
                  >
                    {city}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </SignupPageLayout>
  );
}
