export default function SignupProgress({ step, totalSteps, percentage }) {
  return (
    step && (
      <div className="bg-white p-4">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm font-medium">
            Schritt {step} von {totalSteps}
          </span>
          <span className="text-sm font-medium">{percentage}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-[#C8D65D] h-2.5 rounded-full transition-all"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    )
  );
}
