import React, { useRef } from "react";
import Turnstile from "react-turnstile";

export default function TurnstileCaptcha({
  onVerify,
  onError,
  onExpire,
  justify = "center",
}) {
  const turnstileRef = useRef();

  return (
    <div className={`flex justify-${justify} items-center`}>
      <Turnstile
        ref={turnstileRef}
        sitekey="0x4AAAAAAAyqAmwH65NZhKnh"
        onVerify={onVerify}
        onError={() => {
          onError?.();
        }}
        onExpire={() => {
          onExpire?.();
        }}
      />
    </div>
  );
}
