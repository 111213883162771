export const germanWords = [
  "Haus",
  "Baum",
  "Katze",
  "Hund",
  "Sonne",
  "Mond",
  "Berg",
  "Fluss",
  "Brot",
  "Tisch",
  "Stuhl",
  "Buch",
  "Auto",
  "Zug",
  "Ball",
  "Kind",
  "Blume",
  "Vogel",
  "Fisch",
  "Stern",
  "Wind",
  "Regen",
  "Schnee",
  "Tag",
  "Nacht",
  "Wolke",
  "Garten",
  "Apfel",
  "Birne",
  "Schule",
  "Lehrer",
  "Fenster",
  "Tür",
  "Wasser",
  "Feuer",
  "Erde",
  "Luft",
  "Musik",
  "Tanz",
  "Lied",
  "Bild",
  "Film",
  "Radio",
  "Brief",
  "Stadt",
  "Dorf",
  "Land",
  "Meer",
  "Wald",
  "Wiese",
  "Strasse",
  "Haus",
  "Wohnung",
  "Zimmer",
  "Küche",
  "Bad",
  "Bett",
  "Lampe",
  "Uhr",
  "Geld",
  "Arbeit",
  "Spiel",
  "Sport",
  "Kunst",
  "Leben",
  "Zeit",
  "Jahr",
  "Monat",
  "Woche",
  "Stunde",
  "Farbe",
  "Klang",
  "Kraft",
  "Licht",
  "Raum",
  "Schiff",
  "Traum",
  "Welt",
  "Herz",
  "Kopf",
  "Hand",
  "Fuss",
  "Auge",
  "Ohr",
  "Nase",
  "Mund",
  "Haar",
  "Zahn",
  "Bein",
  "Arm",
  "Blut",
  "Knochen",
  "Freund",
  "Liebe",
  "Glück",
  "Angst",
  "Mut",
  "Hoffnung",
  "Freude",
  "Sorge",
  "Wut",
  "Ruhe",
  "Kraft",
  "Idee",
  "Plan",
  "Weg",
  "Ziel",
  "Sinn",
  "Grund",
  "Form",
  "Ding",
  "Teil",
  "Rest",
  "Mitte",
  "Seite",
  "Ende",
];
