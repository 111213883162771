import React, { useState } from "react";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import { useSignupApproval } from "../../../contexts/SignupApprovalContext";

export default function SignupApprovalMapping() {
  const { signupData, updateSignupData } = useSignupApproval();
  const { goToNext, goToPrevious } = useSignupNavigation();

  const [customerNumber, setCustomerNumber] = useState(
    signupData?.customerNumber || "",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(
    signupData?.customerNumber && signupData?.fetchedAddress ? "success" : null,
  );
  const [statusMessage, setStatusMessage] = useState("");
  const [fetchedAddress, setFetchedAddress] = useState(
    signupData?.fetchedAddress || null,
  );

  // Move the guard clause after all hooks
  if (!signupData) {
    return <div>No data found</div>;
  }

  const verifyCustomerNumber = async (customerNumber) => {
    setIsLoading(true);
    setVerificationStatus(null);
    setStatusMessage("");
    setFetchedAddress(null);

    let retryCount = 0;
    const maxRetries = 6; // 30 seconds total with 5-second intervals

    const checkAddress = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/address/${customerNumber}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
            mode: "cors",
            credentials: "omit",
          },
        );

        if (response.status === 200) {
          const data = await response.json();
          if (data.success) {
            setFetchedAddress(data.data);
            setVerificationStatus("success");
            setStatusMessage("");
            updateSignupData({
              ...signupData,
              customerNumber: customerNumber,
              fetchedAddress: data.data,
            });
          }
          setIsLoading(false);
          return true; // Stop polling
        }

        if (response.status === 202) {
          if (retryCount >= maxRetries) {
            setIsLoading(false);
            setVerificationStatus("error");
            setStatusMessage(
              "Zeitüberschreitung bei der Überprüfung. Bitte versuchen Sie es erneut.",
            );
            return true; // Stop polling
          }
          retryCount++;
          return false; // Continue polling
        }

        // Handle error cases
        throw new Error(`HTTP error! status: ${response.status}`);
      } catch (error) {
        console.error("Error checking address:", error);
        setIsLoading(false);
        setVerificationStatus("error");
        setStatusMessage("Fehler bei der Überprüfung der Kundennummer");
        return true; // Stop polling
      }
    };

    // Initial check
    const shouldStop = await checkAddress();
    if (!shouldStop) {
      // Start polling if initial check returned 202
      const pollInterval = setInterval(async () => {
        const shouldStop = await checkAddress();
        if (shouldStop) {
          clearInterval(pollInterval);
        }
      }, 5000); // Poll every 5 seconds
    }
  };

  const isAddressMatch = (fetchedAddress, institutionAddress) => {
    if (!fetchedAddress) return true;

    const isZipAndNumberMatch =
      fetchedAddress.zip === institutionAddress.zip &&
      fetchedAddress.street === institutionAddress.street;

    const isFullMatch =
      isZipAndNumberMatch &&
      fetchedAddress.name === institutionAddress.name &&
      fetchedAddress.streetNo === institutionAddress.streetNo &&
      fetchedAddress.city === institutionAddress.city;

    // Return an object with status and message
    return {
      status: isFullMatch
        ? "match"
        : isZipAndNumberMatch
          ? "partial"
          : "mismatch",
    };
  };

  const resetVerification = () => {
    setVerificationStatus(null);
    setCustomerNumber("");
    setFetchedAddress(null);
    setStatusMessage("");
    updateSignupData({
      ...signupData,
      customerNumber: undefined,
      fetchedAddress: undefined,
    });
  };

  return (
    <SignupPageLayout
      title="Kundennummer"
      step={2}
      totalSteps={7}
      progress={15}
      description="Konto mit Abacus-Kundennummer verknüpfen."
      footer={
        <SignupFooter
          isValid={verificationStatus === "success"}
          onSubmit={goToNext}
          onBack={goToPrevious}
          submitText="Weiter"
        />
      }
    >
      <div className="space-y-4">
        {/* Institution Address */}
        <div className="bg-white shadow p-4">
          <div className="flex justify-between items-start">
            <h3 className="font-semibold">Adresse</h3>
          </div>
          <div
            className={`space-y-1 text-sm ${
              isAddressMatch(fetchedAddress, signupData.institution).status ===
              "mismatch"
                ? "line-through"
                : ""
            }`}
          >
            <p>{signupData.institution.name}</p>
            <p>
              {signupData.institution.street} {signupData.institution.streetNo}
            </p>
            <p>
              {signupData.institution.zip} {signupData.institution.city}
            </p>
          </div>
        </div>

        {/* Fetched Address */}
        {fetchedAddress && (
          <div className="bg-white shadow p-4">
            <div className="flex justify-between items-start">
              <h3 className="font-semibold">Gefundene Adresse</h3>
              {isAddressMatch(fetchedAddress, signupData.institution).status ===
              "match" ? (
                <span className="text-green-500 text-sm">
                  ✓ Übereinstimmung
                </span>
              ) : isAddressMatch(fetchedAddress, signupData.institution)
                  .status === "partial" ? (
                <span className="text-yellow-500 text-sm">
                  ⚠ Nicht identisch
                </span>
              ) : (
                <span className="text-red-500 text-sm">
                  ⚠ Keine Übereinstimmung
                </span>
              )}
            </div>
            <div className="space-y-1 text-sm">
              <p>
                {fetchedAddress.firstname} {fetchedAddress.name}
              </p>
              <p>
                {fetchedAddress.street} {fetchedAddress.streetNo}
              </p>
              <p>
                {fetchedAddress.zip} {fetchedAddress.city}
              </p>
            </div>
          </div>
        )}

        {/* Customer Number Input */}
        <div className="bg-white shadow p-4">
          <div className="flex justify-between items-start">
            <label
              htmlFor="customerNumber"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Abacus-Kundennummer <span className="text-red-500">*</span>
            </label>
            {verificationStatus === "success" && (
              <button
                onClick={resetVerification}
                className="text-sm text-[#aabc33] hover:text-[#899926]"
              >
                Bearbeiten
              </button>
            )}
          </div>
          <input
            type="text"
            id="customerNumber"
            value={customerNumber}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setCustomerNumber(value);
              }
            }}
            disabled={isLoading || verificationStatus === "success"}
            className="w-full py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all disabled:opacity-60 disabled:border-0"
            placeholder="10000"
          />

          {/* Status Message */}
          {statusMessage && (
            <div
              className={`mt-2 text-sm ${
                verificationStatus === "success"
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {statusMessage}
            </div>
          )}

          {verificationStatus !== "success" && (
            <button
              onClick={() => verifyCustomerNumber(customerNumber)}
              disabled={isLoading || customerNumber.trim().length < 4}
              className={`mt-4 w-full py-2 rounded-lg font-medium transition-colors disabled:opacity-60 disabled:cursor-not-allowed disabled:bg-neutral-300 ${
                isLoading
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-[#C8D65D] hover:bg-[#aabc33] text-white"
              }`}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Überprüfe...
                </span>
              ) : (
                "Kundennummer überprüfen"
              )}
            </button>
          )}
        </div>
      </div>
    </SignupPageLayout>
  );
}
