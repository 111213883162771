import React, { useState, useRef, useEffect } from "react";
import { useSignup } from "../../../contexts/SignupContext";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import {
  XIcon,
  ConfirmIcon,
  AddIcon,
  EditIcon,
} from "../../../components/Icons";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";

export default function SignupEmployees() {
  const { signupData, updateSignupData } = useSignup();
  const { goToNext, _ } = useSignupNavigation();
  const [employees, setEmployees] = useState(signupData.employees || []);
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [progressPercentage, setProgressPercentage] = useState(60);
  const listEndRef = useRef(null);

  useEffect(() => {
    if (listEndRef.current) {
      setTimeout(() => {
        listEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 100);
    }
  }, [employees]);

  // Get departments from context
  const availableDepartments = signupData.departments || [];
  // Add contact data reference
  const contactData = signupData.contact || {};

  const rights = [
    {
      id: "can_edit_residents",
      label: "Bewohner erfassen/bearbeiten",
      default: true,
    },
    { id: "can_buy", label: "Bestellungen aufgeben", default: true },
    {
      id: "can_access_all_departments",
      label: "Zugriff auf Abteilungen einschränken",
      default: false, // Changed to false since we're reversing the logic
    },
  ];

  // Add a helper function to handle validation before changing edit state
  const validateAndChangeEdit = (newEditId) => {
    // Validate current edit before changing
    if (editingId && !confirmEdit(editingId)) {
      return false;
    }
    setEditingId(newEditId);
    return true;
  };

  const addEmployee = () => {
    if (!validateAndChangeEdit(null)) return;

    const defaultRights = rights
      .filter((right) => right.default)
      .map((right) => right.id);

    const newEmployee = {
      id: Date.now(),
      type: "person",
      firstname: "",
      lastname: "",
      groupname: "",
      email: "",
      jobTitle: "",
      departments: [""],
      rights: defaultRights,
    };
    setEmployees([...employees, newEmployee]);
    setEditingId(newEmployee.id);
    setErrors({});
  };

  const updateEmployee = (id, field, value) => {
    const employee = employees.find((emp) => emp.id === id);
    let updatedEmployee = { ...employee, [field]: value };

    // Clear appropriate fields when switching types
    if (field === "type") {
      if (value === "group") {
        updatedEmployee = {
          ...updatedEmployee,
          firstname: "",
          lastname: "",
        };
      } else {
        updatedEmployee = {
          ...updatedEmployee,
          groupname: "",
        };
      }
    }

    setEmployees(
      employees.map((emp) => (emp.id === id ? updatedEmployee : emp)),
    );
  };

  const deleteEmployee = (id) => {
    setEmployees(employees.filter((employee) => employee.id !== id));
    setEditingId(null);
    setProgressPercentage(Math.min(80, 60 + (employees.length - 1) * 5));
  };

  const validateEmployee = (employee) => {
    const newErrors = {};

    if (employee.type === "person") {
      if (!employee.firstname.trim()) {
        newErrors.firstname = "Vorname ist erforderlich";
      }
      if (!employee.lastname.trim()) {
        newErrors.lastname = "Nachname ist erforderlich";
      }

      // Person-specific duplicate check
      const duplicateName = employees.find(
        (emp) =>
          emp.id !== employee.id &&
          emp.firstname.toLowerCase().trim() ===
            employee.firstname.toLowerCase().trim() &&
          emp.lastname.toLowerCase().trim() ===
            employee.lastname.toLowerCase().trim(),
      );
      if (duplicateName) {
        newErrors.fullName =
          "Ein Mitarbeiter mit diesem Namen existiert bereits";
      }

      // Contact person check
      if (
        employee.firstname.toLowerCase().trim() ===
          contactData.firstname.toLowerCase().trim() &&
        employee.lastname.toLowerCase().trim() ===
          contactData.lastname.toLowerCase().trim()
      ) {
        newErrors.fullName = "Bitte erfassen Sie nur Ihre Mitarbeiter.";
      }
    } else {
      // Group validation
      if (!employee.groupname.trim()) {
        newErrors.groupname = "Gruppenname ist erforderlich";
      }

      // Group duplicate check
      const duplicateGroup = employees.find(
        (emp) =>
          emp.id !== employee.id &&
          emp.type === "group" &&
          emp.groupname.toLowerCase().trim() ===
            employee.groupname.toLowerCase().trim(),
      );
      if (duplicateGroup) {
        newErrors.groupname = "Eine Gruppe mit diesem Namen existiert bereits";
      }
    }

    // Common email validation
    if (!employee.email.trim()) {
      newErrors.email = "E-Mail ist erforderlich";
    } else if (!/\S+@\S+\.\S+/.test(employee.email)) {
      newErrors.email = "Ungültige E-Mail-Adresse";
    }

    const duplicateEmail = employees.find(
      (emp) =>
        emp.id !== employee.id &&
        emp.email.toLowerCase().trim() === employee.email.toLowerCase().trim(),
    );
    if (duplicateEmail) {
      newErrors.email = "Diese E-Mail-Adresse wird bereits verwendet";
    } else if (
      employee.email.toLowerCase().trim() ===
      contactData.email.toLowerCase().trim()
    ) {
      newErrors.email =
        "Der Mitarbeiter muss eine abweichende E-Mail-Adresse haben.";
    }

    return newErrors;
  };

  const confirmEdit = (id) => {
    const employee = employees.find((emp) => emp.id === id);
    const newErrors = validateEmployee(employee);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }

    setErrors({});
    setEditingId(null);
    setProgressPercentage(Math.min(80, 60 + employees.length * 5));
    return true;
  };

  const handleSubmit = () => {
    updateSignupData("employees", employees);
    goToNext();
  };

  const isValid = () => {
    // Check if any employee is currently being edited
    if (editingId !== null) return false;

    // Check if all employees have required fields
    return employees.every((employee) => {
      if (employee.type === "group") {
        return employee.groupname?.trim() && employee.email?.trim();
      }
      return (
        employee.firstname?.trim() &&
        employee.lastname?.trim() &&
        employee.email?.trim()
      );
    });
  };

  return (
    <SignupPageLayout
      title="Mitarbeiter"
      step={4}
      totalSteps={5}
      progress={progressPercentage}
      description="Bitte erfassen Sie die Mitarbeiter Ihrer Einrichtung."
      explainTooltip="Ihre Mitarbeiter können Sie auch später ergänzen."
      footer={
        <SignupFooter
          isValid={isValid()}
          onSubmit={handleSubmit}
          allowSkip={true}
        />
      }
    >
      <div className="space-y-4">
        {/* Add contact person card */}
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <span className="text-lg font-semibold">
                {contactData.firstname} {contactData.lastname}
              </span>
              <div className="text-sm mb-1">{contactData.email}</div>
              <div className="text-sm text-gray-500">
                <div>Zugriff auf alle Abteilungen</div>
              </div>
            </div>
            <div className="bg-[#C8D65D] text-white px-3 py-1 rounded-full text-sm">
              Admin
            </div>
          </div>
        </div>

        {employees.map((employee) => (
          <div key={employee.id} className="bg-white rounded-lg shadow p-4">
            {editingId === employee.id ? (
              <div className="space-y-2">
                <div className="flex space-x-4 mb-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="person"
                      checked={employee.type !== "group"}
                      onChange={() =>
                        updateEmployee(employee.id, "type", "person")
                      }
                      className="mr-2"
                    />
                    Person
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="group"
                      checked={employee.type === "group"}
                      onChange={() =>
                        updateEmployee(employee.id, "type", "group")
                      }
                      className="mr-2"
                    />
                    Gruppe
                  </label>
                </div>

                {employee.type !== "group" ? (
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <input
                        type="text"
                        value={employee.firstname}
                        onChange={(e) =>
                          updateEmployee(
                            employee.id,
                            "firstname",
                            e.target.value,
                          )
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            confirmEdit(employee.id);
                          }
                        }}
                        placeholder="Vorname (*)"
                        className={`w-full text-lg border-b rounded-none ${
                          errors.firstname || errors.fullName
                            ? "border-red-500"
                            : "border-gray-300"
                        } focus:outline-none focus:border-[#C8D65D]`}
                        autoFocus={true}
                      />
                      {errors.firstname && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.firstname}
                        </p>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        value={employee.lastname}
                        onChange={(e) =>
                          updateEmployee(
                            employee.id,
                            "lastname",
                            e.target.value,
                          )
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            confirmEdit(employee.id);
                          }
                        }}
                        placeholder="Nachname (*)"
                        className={`w-full text-lg border-b rounded-none  ${
                          errors.lastname || errors.fullName
                            ? "border-red-500"
                            : "border-gray-300"
                        } focus:outline-none focus:border-[#C8D65D]`}
                      />
                      {errors.lastname && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.lastname}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <input
                      type="text"
                      value={employee.groupname || ""}
                      onChange={(e) =>
                        updateEmployee(employee.id, "groupname", e.target.value)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          confirmEdit(employee.id);
                        }
                      }}
                      placeholder="Gruppenname (*)"
                      className={`w-full text-lg border-b rounded-none ${
                        errors.groupname ? "border-red-500" : "border-gray-300"
                      } focus:outline-none focus:border-[#C8D65D]`}
                      autoFocus={true}
                    />
                    {errors.groupname && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.groupname}
                      </p>
                    )}
                  </div>
                )}

                <div>
                  <input
                    type="email"
                    value={employee.email}
                    onChange={(e) =>
                      updateEmployee(employee.id, "email", e.target.value)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        confirmEdit(employee.id);
                      }
                    }}
                    placeholder="E-Mail (*)"
                    className={`w-full text-lg border-b rounded-none my-2 ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    } focus:outline-none focus:border-[#C8D65D]`}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                  )}
                </div>

                {employee.type !== "group" && (
                  <div>
                    <input
                      type="text"
                      value={employee.jobTitle || ""}
                      onChange={(e) =>
                        updateEmployee(employee.id, "jobTitle", e.target.value)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          confirmEdit(employee.id);
                        }
                      }}
                      placeholder="Funktion (z. B. Pflegedienst)"
                      className="w-full text-lg border-b rounded-none my-2 border-gray-300 focus:outline-none focus:border-[#C8D65D]"
                    />
                  </div>
                )}

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Berechtigungen
                  </label>
                  <div className="space-y-2">
                    {rights.map((right) => (
                      <label
                        key={right.id}
                        className="flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          defaultChecked={
                            employee.rights.includes(right.id) || right.default
                          }
                          onChange={(e) => {
                            const newRights = e.target.checked
                              ? [...employee.rights, right.id]
                              : employee.rights.filter((r) => r !== right.id);
                            updateEmployee(employee.id, "rights", newRights);
                          }}
                          className="form-checkbox text-[#C8D65D]"
                        />
                        <span>{right.label}</span>
                      </label>
                    ))}
                  </div>
                </div>

                {employee.rights.includes("can_access_all_departments") && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Abteilungen
                    </label>
                    <div className="space-y-2">
                      {/* Ensure departments array exists and has at least one item */}
                      {(employee.departments?.length
                        ? employee.departments
                        : [""]
                      ).map((deptId, index) => (
                        <div key={index} className="flex gap-2">
                          <select
                            value={deptId}
                            onChange={(e) => {
                              const newDepts = [
                                ...(employee.departments || []),
                              ];
                              newDepts[index] = e.target.value;
                              updateEmployee(
                                employee.id,
                                "departments",
                                newDepts,
                              );
                            }}
                            className="w-full p-2 border rounded-md"
                          >
                            <option value="">Abteilung wählen</option>
                            {availableDepartments
                              .filter(
                                (dept) =>
                                  // Show if either it's the current selection or not selected elsewhere
                                  String(dept.id) === deptId ||
                                  !(employee.departments || []).includes(
                                    String(dept.id),
                                  ),
                              )
                              .map((dept) => (
                                <option key={dept.id} value={dept.id}>
                                  {dept.name}
                                </option>
                              ))}
                          </select>
                          {/* Only show remove button if there's more than one department */}
                          {(employee.departments || []).length > 1 && (
                            <button
                              onClick={() => {
                                const newDepts = employee.departments.filter(
                                  (_, i) => i !== index,
                                );
                                updateEmployee(
                                  employee.id,
                                  "departments",
                                  newDepts,
                                );
                              }}
                              className="text-neutral-500"
                            >
                              <XIcon />
                            </button>
                          )}
                        </div>
                      ))}

                      {/* Add Department Button */}
                      {availableDepartments.length >
                        (employee.departments || []).length && (
                        <button
                          onClick={() => {
                            const selectedDepts = employee.departments || [];
                            const hasUnselectedDepts =
                              availableDepartments.some(
                                (dept) =>
                                  !selectedDepts.includes(String(dept.id)),
                              );

                            if (hasUnselectedDepts) {
                              const newDepts = [...selectedDepts, ""];
                              updateEmployee(
                                employee.id,
                                "departments",
                                newDepts,
                              );
                            }
                          }}
                          className="flex items-center mt-2 text-neutral-500"
                        >
                          <AddIcon />
                          <span>Abteilung ergänzen</span>
                        </button>
                      )}
                    </div>
                  </div>
                )}

                <div className="flex justify-end space-x-2">
                  <button
                    className="text-neutral-500"
                    onClick={() => confirmEdit(employee.id)}
                  >
                    <ConfirmIcon />
                  </button>
                  {/* Delete button */}
                  <button
                    className="text-neutral-500"
                    onClick={() => deleteEmployee(employee.id)}
                  >
                    <XIcon />
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between">
                <div>
                  <span
                    className="text-lg font-semibold cursor-pointer"
                    onClick={() => validateAndChangeEdit(employee.id)}
                  >
                    {employee.firstname && employee.lastname
                      ? `${employee.firstname} ${employee.lastname}`
                      : ""}
                    {employee.groupname && `${employee.groupname}`}
                  </span>
                  <div className="text-sm mb-1">{employee.email}</div>
                  <div className="text-sm text-gray-500">
                    {!employee.rights.includes("can_access_all_departments") ? (
                      <div>Zugriff auf alle Abteilungen</div>
                    ) : (
                      <div>
                        {(employee.departments || [])
                          .map((deptId) => {
                            const dept = availableDepartments.find(
                              (d) => String(d.id) === String(deptId),
                            );
                            return dept?.name;
                          })
                          .filter(Boolean)
                          .join(", ") || "Keine Abteilungen ausgewählt"}
                      </div>
                    )}
                    <div className="hidden">
                      {employee.rights
                        .map(
                          (r) => rights.find((right) => right.id === r)?.label,
                        )
                        .join(", ")}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <button
                    className="text-neutral-500 mr-2"
                    onClick={() => validateAndChangeEdit(employee.id)}
                  >
                    <EditIcon />
                  </button>
                  <button
                    className="text-neutral-500"
                    onClick={() => {
                      if (validateAndChangeEdit(null)) {
                        deleteEmployee(employee.id);
                      }
                    }}
                  >
                    <XIcon />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
        {/* Add Employee button */}
        {!editingId && (
          <div>
            <button
              className="flex items-center w-full text-neutral-500"
              onClick={addEmployee}
            >
              <AddIcon />
              <span className="text-lg font-semibold">
                Mitarbeiter hinzufügen
              </span>
            </button>
          </div>
        )}
        <div ref={listEndRef} />
      </div>
    </SignupPageLayout>
  );
}
