import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../fonts/human-sans/fonts.css";
import MedisuiteHeader from "./partials/MedisuiteHeader";
import MedisuiteSliderSection from "./partials/MedisuiteSliderSection";
import MedisuiteResidentIllustration from "./partials/MedisuiteResidentIllustration";
import MedisuiteStationsIllustration from "./partials/MedisuiteStationsIllustration";
import MedisuitePrescriptionIllustration from "./partials/MedisuitePrescriptionIllustration";
import MedisuiteDailyNeedsIllustration from "./partials/MedisuiteDailyNeedsIllustration";
import MedisuiteOrderIllustration from "./partials/MedisuiteOrderIllustration";
import MedisuiteInvoicingIllustration from "./partials/MedisuiteInvoicingIllustration";
import MedisuiteStatisticsIllustration from "./partials/MedisuiteStatisticsIllustration";
import MedisuiteFAQ from "./partials/MedisuiteFAQ";
import MedisuiteFree from "./partials/MedisuiteFree";
import MedisuiteSiteFooter from "./partials/MedisuiteSiteFooter";
import { TypeAnimation } from "react-type-animation";

export default function MedisuiteHome() {
  const [featuresTabSlider, setFeaturesTabSlider] = useState(null);
  const [heroVideoSlider, setHeroVideoSlider] = useState(null);

  const featuresTabSettings = {
    asNavFor: heroVideoSlider,
    initialSlide: 0,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: false,
    infinite: false,
    centerMode: false,
    centerPadding: "20px",
    dots: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 640, // sm breakpoint
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  const heroVideoSettings = {
    asNavFor: featuresTabSlider, // Connect to the tabs slider
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 9000,
    pauseOnHover: false,
    pauseOnFocus: true,
  };
  return (
    <div
      id="landing-page"
      className="min-h-screen mx-auto bg-white text-gray-800 font-human"
    >
      <MedisuiteHeader />
      <main
        id="main-content"
        className="bg-gradient-to-b from-[#f8fff7] to-[#fff]"
        style={{ paddingTop: "100px" }}
      >
        <div className="max-w-[1300px] mx-auto px-4 sm:px-12 md:px-24 lg:px-32 xl:px-16 md:pt-4">
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-medium text-center mb-4">
            <TypeAnimation
              sequence={[
                "Medisuite ist...\n",
                500,
                "Medisuite ist...\nDie digitale Lösung \nfür Pflege & Spitex"
              ]}
              wrapper="span"
              speed={50}
              cursor={false}
              style={{ whiteSpace: "pre-line" }}
            />
          </h1>
          <p className="text-sm sm:text-base lg:text-lg text-center mb-6 sm:px-12 lg:px-24 xl:px-32">
            Kostenlose App vereinfacht bewohnerbezogene Beschaffung&nbsp;und&nbsp;Abrechnung{" "}
            <br className="hidden lg:block" />
            von Pflegeartikeln und Hilfsmitteln (inklusive MiGeL)
          </p>
          <div className="mb-8 flex flex-col justify-center gap-4">
            <div className="flex justify-center">
              <Link
                to="/demo"
                className="bg-[#abbe33] text-white text-center py-2 p-4 rounded-md hover:bg-[#99a92d] transition-colors duration-300 text-sm sm:text-base lg:text-lg flex items-center"
              >
                Kostenlose Vorführung anfragen{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 ml-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
            </div>
            <span className="flex justify-center text-xs sm:text-sm lg:text-md text-neutral-500 text-center">
              ... oder{" "}
              <Link
                to="/signup"
                className="text-[#899926] hover:text-[#6b741d] transition-colors duration-300 ml-1"
              >
                erstellen Sie gleich Ihr Konto
              </Link>
            </span>
          </div>
          <div className="bg-white rounded-lg border border-[E4EAEE] mb-4 mx-auto features-tab-container">
            <Slider
              {...featuresTabSettings}
              ref={(featuresTabSlider) =>
                setFeaturesTabSlider(featuresTabSlider)
              }
              className="text-xs features-tab px-2 sm:px-6 md:px-12 lg:px-24 xl:px-48"
            >
              {[
                { icon: "KOSd7jttkb9P", label: "Abteilungen" },
                { icon: "7zeBpzcAjYvv", label: "Bewohner" },
                { icon: "WGKV9h1U1gvI", label: "Verordnungen" },
                { icon: "17446", label: "Tagesbedarf" },
                { icon: "PkLIOcCVAstR", label: "Bestellungen" },
                { icon: "7c8xZJbGXACs", label: "Abrechnung" },
                { icon: "98027", label: "Statistiken" },
              ].map((item, index) => (
                <div
                  key={index}
                  className="!flex flex-col items-center p-4 gap-1"
                >
                  <img
                    src={`https://img.icons8.com/?size=100&id=${item.icon}&format=png&color=607D8B`}
                    height={24}
                    width={24}
                    alt=""
                  />
                  {item.label}
                </div>
              ))}
            </Slider>
          </div>

          <Slider
            {...heroVideoSettings}
            ref={(heroVideoSlider) => setHeroVideoSlider(heroVideoSlider)}
          >
            <MedisuiteSliderSection
              title="Effiziente Organisation mit Abteilungen & Benutzerrollen"
              subtitle="Strukturieren Sie Ihre Einrichtung durch die Einteilung in Abteilungen bzw. Wohngruppen. "
              description="Vergeben Sie individuelle Benutzerkonten für Ihre Mitarbeiter mit Zugriffsberechtigungen für die relevanten Abteilungen. So optimieren Sie die Verwaltung und gewährleisten gleichzeitig die Datensicherheit."
              illustration={<MedisuiteStationsIllustration />}
            />
            <MedisuiteSliderSection
              title="Zeit sparen mit zentraler Bewohnerverwaltung"
              subtitle="Alle Bewohnerdaten im Blick – schnell und einfach."
              description="Optional: Hinterlegen Sie auch die Krankenkassen-Informationen."
              illustration={<MedisuiteResidentIllustration />}
            />
            <MedisuiteSliderSection
              title="Verordnungen"
              subtitle="Sparen Sie Zeit bei der Verwaltung von Inkontinenzverordnungen."
              description="Medisuite macht die Verwaltung von Inkontinenzverordnungen zum Kinderspiel. Verordnungen hochladen, Bewohnern zuordnen, fertig! Die Abrechnung erledigen wir für Sie."
              illustration={<MedisuitePrescriptionIllustration />}
            />

            <MedisuiteSliderSection
              title="Individuelle Versorgung"
              subtitle="Erfassen Sie den Bedarf an Inkontinenzeinlagen und Zubehör für jeden Bewohner."
              description="So läuft die Versorgung reibungslos und ohne Engpässe."
              illustration={<MedisuiteDailyNeedsIllustration />}
            />
            <MedisuiteSliderSection
              title="Automatische Sammelbestellungen"
              subtitle="Bestellen Sie mit nur einem Klick für mehrere Bewohner gleichzeitig."
              description="Geben Sie Ihren Lagerbestand ein und unser System berechnet die optimale Bestellmenge basierend auf dem Bedarf Ihrer Bewohner. So gewährleisten Sie eine optimale Versorgung und vermeiden unnötige Kosten."
              illustration={<MedisuiteOrderIllustration />}
            />
            <MedisuiteSliderSection
              title="Entspannt abrechnen"
              subtitle="Rechnungsstellung & Kassenabrechnung - wir übernehmen das für Sie."
              description="Wir erstellen rechtskonforme Rechnungen und rechnen direkt mit den Krankenkassen ab. So sparen Sie Zeit und Ressourcen und können sich ganz auf die Betreuung Ihrer Bewohner konzentrieren."
              illustration={<MedisuiteInvoicingIllustration />}
            />
            <MedisuiteSliderSection
              title="Statistiken"
              subtitle="Mit dem MiGeL-Barometer die Kosten im Griff."
              description="Überblicken Sie die Verbrauchsdaten Ihrer Bewohner und behalten Sie den jährlichen Höchstvergütungsbetrag im Auge.  So vermeiden Sie unnötige Kosten und können bei Bedarf rechtzeitig Umstufungen beantragen."
              illustration={<MedisuiteStatisticsIllustration />}
            />
          </Slider>
          <MedisuiteFAQ />
          <MedisuiteFree />
          {/*<div className="flex flex-col lg:flex-row">
          <MedisuiteAboutUs />
          <MedisuitePersonal />
        <MedisuiteContactUs />
        </div>*/}
        </div>
      </main>
      <MedisuiteSiteFooter />
    </div>
  );
}
