import React from "react";

export default function MedisuiteInvoicingIllustration() {
  return (
    <img
      src="/home/images/medisuite/invoicing_illustration.png"
      alt="Invoicing"
      className="mt-6 md:mt-0 p-3 rounded-lg max-h-[450px] mx-auto"
    />
  );
}
