export default function SignupHeader({
  title,
  onBack,
  showBackButton = true,
  explainTooltip,
}) {
  return (
    <header className="p-4 bg-[#C8D65D]">
      <div className="flex items-center justify-between">
        {showBackButton ? (
          <button className="text-white" onClick={onBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M19 12H5M12 19l-7-7 7-7" />
            </svg>
          </button>
        ) : (
          <div className="w-6"></div>
        )}
        <h1 className="text-xl font-bold text-white">{title}</h1>
        {explainTooltip ? (
          <div className="relative group">
            <button className="text-white w-6 h-6 flex items-center justify-center rounded-full border-2 border-white">
              ?
            </button>
            <div
              className="absolute right-0 w-48 p-2 bg-white text-gray-800 text-sm rounded shadow-lg 
                          invisible group-hover:visible transition-all duration-200 z-10"
            >
              {explainTooltip}
            </div>
          </div>
        ) : (
          <div className="w-6"></div>
        )}
      </div>
    </header>
  );
}
