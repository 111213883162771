import React, { useState } from "react";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import { useSignupApproval } from "../../../contexts/SignupApprovalContext";

export default function SignupApprovalLogo() {
  const { signupData, updateSignupData } = useSignupApproval();
  const { goToNext, goToPrevious } = useSignupNavigation();
  const [previewUrl, setPreviewUrl] = useState(signupData.logoUrl);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);

      const formData = new FormData();
      formData.append("logo", file);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/customer/logo/${signupData.customerNumber}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
            mode: "cors",
            credentials: "omit",
            body: formData,
          },
        );

        if (response.ok) {
          const data = await response.json();
          updateSignupData({ ...signupData, logoUrl: data.logoUrl });
        } else {
          console.error("Failed to upload logo");
        }
      } catch (error) {
        console.error("Error uploading logo:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleLogoDelete = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/customer/logo/${signupData.customerNumber}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
          },
          mode: "cors",
          credentials: "omit",
        },
      );

      if (response.ok) {
        setPreviewUrl(null);
        updateSignupData({ ...signupData, logoUrl: null });
      } else {
        console.error("Failed to delete logo");
      }
    } catch (error) {
      console.error("Error deleting logo:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SignupPageLayout
      title="Logo"
      step={6}
      totalSteps={7}
      progress={75}
      description="Bitte laden Sie das Kundenlogo hoch."
      footer={
        <SignupFooter
          isValid={signupData.logoUrl !== null && !isLoading}
          onSubmit={goToNext}
          onBack={goToPrevious}
          submitText="Weiter"
        />
      }
    >
      <div className="space-y-4">
        <div className="flex flex-col items-center space-y-4">
          <label
            htmlFor="logo-upload"
            className={`w-full max-w-md p-4 border-2 border-dashed border-gray-300 rounded-lg ${
              !isLoading
                ? "cursor-pointer hover:border-blue-500"
                : "cursor-wait"
            } transition-colors`}
          >
            <div className="flex flex-col items-center space-y-2">
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                </div>
              ) : previewUrl ? (
                <img
                  src={signupData.logoUrl}
                  alt="Logo Preview"
                  className="max-w-[200px] max-h-[200px] object-contain"
                />
              ) : (
                <>
                  <svg
                    className="w-8 h-8 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  <span className="text-sm text-gray-500">
                    Klicken Sie hier, um ein Logo hochzuladen
                  </span>
                </>
              )}
            </div>
            <input
              id="logo-upload"
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleLogoUpload}
              disabled={isLoading}
            />
          </label>
          {previewUrl && (
            <button
              onClick={handleLogoDelete}
              className={`text-sm text-red-600 hover:text-red-800 ${
                isLoading ? "opacity-50 cursor-wait" : ""
              }`}
              disabled={isLoading}
            >
              Logo entfernen
            </button>
          )}
          <p className="text-sm text-gray-500">
            Empfohlene Grösse: 300x150 Pixel, Maximale Dateigrösse: 2MB
          </p>
        </div>
      </div>
    </SignupPageLayout>
  );
}
