import React from "react";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";

export default function SignupSuccess() {
  return (
    <SignupPageLayout totalSteps={5}>
      <div className="flex flex-col items-center justify-center min-h-full text-center space-y-6 py-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-24 w-24 text-[#aabc33]"
        >
          <path
            fillRule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
            clipRule="evenodd"
          />
        </svg>

        <div className="space-y-4 max-w-2xl">
          <h2 className="text-2xl font-semibold text-gray-800">
            Vielen Dank für Ihre Registrierung!
          </h2>

          <div className="mt-6">
            <p className="text-gray-700">
              Eine unserer Pflegefachpersonen wird sich in Kürze mit&nbsp;Ihnen
              in Verbindung setzen, um Ihr individuelles&nbsp;Sortiment zu
              definieren und
              eine&nbsp;kostenlose&nbsp;Einführung&nbsp;zu&nbsp;planen.
            </p>
            <p className="mt-4 font-semibold">
              Sie dürfen dieses Fenster nun schliessen.
            </p>
          </div>
        </div>
      </div>
    </SignupPageLayout>
  );
}
