import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MedisuiteHome from "./pages/medisuite/MedisuiteHome";
import ProtectedSignupRoute from "./components/ProtectedSignupRoute";
import { SignupProvider } from "./contexts/SignupContext";
import SignupContact from "./pages/medisuite/Signup/SignupContact";
import SignupBusiness from "./pages/medisuite/Signup/SignupBusiness";
import SignupDepartments from "./pages/medisuite/Signup/SignupDepartments";
import SignupEmployees from "./pages/medisuite/Signup/SignupEmployees";
import SignupSummary from "./pages/medisuite/Signup/SignupSummary";
import SignupSuccess from "./pages/medisuite/Signup/SignupSuccess";
import SignupPending from "./pages/medisuite/Signup/SignupPending";

// Import new SignupApproval components
import SignupApprovalOverview from "./pages/medisuite/SignupApproval/SignupApprovalOverview";
import SignupApprovalMapping from "./pages/medisuite/SignupApproval/SignupApprovalMapping";
import SignupApprovalCompanyType from "./pages/medisuite/SignupApproval/SignupApprovalCompanyType";
import SignupApprovalRepresentative from "./pages/medisuite/SignupApproval/SignupApprovalRepresentative";
import SignupApprovalFavorits from "./pages/medisuite/SignupApproval/SignupApprovalFavorits";
import SignupApprovalLogo from "./pages/medisuite/SignupApproval/SignupApprovalLogo";
import SignupApprovalFinal from "./pages/medisuite/SignupApproval/SignupApprovalFinal";
import SignupApprovalSuccess from "./pages/medisuite/SignupApproval/SignupApprovalSuccess";
import { SignupApprovalProvider } from "./contexts/SignupApprovalContext";
import DemoRequest from "./pages/medisuite/DemoRequest/DemoRequestForm";

function App() {
  return (
    <SignupProvider>
      <SignupApprovalProvider>
        <BrowserRouter basename="/home">
          <Routes>
            <Route path="/" element={<MedisuiteHome />} />

            {/* Signup Flow */}
            <Route
              path="/signup"
              element={
                <Navigate
                  to={
                    localStorage.getItem("signupPending")
                      ? "/signup/pending"
                      : "/signup/contact"
                  }
                  replace
                />
              }
            />
            <Route path="/signup">
              <Route path="contact" element={<SignupContact />} />
              <Route
                path="business"
                element={
                  <ProtectedSignupRoute>
                    <SignupBusiness />
                  </ProtectedSignupRoute>
                }
              />
              <Route
                path="departments"
                element={
                  <ProtectedSignupRoute>
                    <SignupDepartments />
                  </ProtectedSignupRoute>
                }
              />
              <Route
                path="employees"
                element={
                  <ProtectedSignupRoute>
                    <SignupEmployees />
                  </ProtectedSignupRoute>
                }
              />
              <Route
                path="summary"
                element={
                  <ProtectedSignupRoute>
                    <SignupSummary />
                  </ProtectedSignupRoute>
                }
              />
            </Route>

            {/* Success page without protection */}
            <Route path="/signup/success" element={<SignupSuccess />} />
            {/* Pending page without protection */}
            <Route path="/signup/pending" element={<SignupPending />} />

            {/* SignupApproval Flow */}
            <Route
              path="/signup-approval/*"
              element={
                <Routes>
                  <Route path="overview" element={<SignupApprovalOverview />} />
                  <Route path="mapping" element={<SignupApprovalMapping />} />
                  <Route
                    path="company-type"
                    element={<SignupApprovalCompanyType />}
                  />
                  <Route
                    path="representative"
                    element={<SignupApprovalRepresentative />}
                  />
                  <Route path="favorits" element={<SignupApprovalFavorits />} />
                  <Route path="logo" element={<SignupApprovalLogo />} />
                  <Route path="final" element={<SignupApprovalFinal />} />
                  <Route path="success" element={<SignupApprovalSuccess />} />
                </Routes>
              }
            />

            <Route path="/demo" element={<DemoRequest />} />
          </Routes>
        </BrowserRouter>
      </SignupApprovalProvider>
    </SignupProvider>
  );
}

export default App;
