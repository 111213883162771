import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function MedisuiteHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const headerOffset = 80;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    setIsMenuOpen(false);
  };

  return (
    <header
      id="header"
      className="shadow-sm fixed top-0 w-full z-[200] bg-[#f9fff8] bg-opacity-95 backdrop-blur-[10px]"
    >
      <div className="flex justify-between items-center max-w-[1300px] mx-auto py-4 px-4 sm:px-12 md:px-24 lg:px-32 xl:px-16">
        <div className="logo hidden md:block">
          <div className="logo">
            <div
              className="w-48 h-11 bg-contain bg-no-repeat"
              style={{
                backgroundImage: 'url("/home/images/medisuite/logo.png")',
              }}
            >
              <span className="sr-only">Medisuite von Scheitlin-Medical</span>
            </div>
          </div>
        </div>
        <div className="logo md:hidden">
          <div className="logo">
            <div
              className="w-12 h-12 bg-cover bg-center rounded-full"
              style={{
                backgroundImage: 'url("/home/images/medisuite/logo-mobile.png")',
              }}
            >
              <span className="sr-only">Medisuite von Scheitlin-Medical</span>
            </div>
          </div>
        </div>

        <div className="hidden md:flex space-x-8">
          <a
            href="#faq"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("faq");
            }}
            className="text-[#586979] hover:text-[#899926] transition-colors duration-100"
          >
            FAQ
          </a>
          <div className="w-[1px] bg-[#E8EDF0]"></div>
          <a
            href="#price"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("price");
            }}
            className="text-[#586979] hover:text-[#899926] transition-colors duration-100"
          >
            Preis
          </a>
        </div>

        <div className="md:hidden flex items-center">
          <Link to="/demo" className="md:hidden mr-2">
            <button className="px-4 py-2 text-sm font-semibold text-white bg-black rounded-full flex items-center hover:bg-gray-800 hover:text-white transition-colors duration-100">
              Demo anfragen
            </button>
          </Link>
          <button className="p-2" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <svg
              className="w-6 h-6 transition-transform duration-200"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{
                transform: isMenuOpen ? "rotate(180deg)" : "rotate(0deg)",
              }}
            >
              {isMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>

        <div className="hidden md:flex space-x-2">
          <Link to="https://www.medisuite.ch/login">
            <button className="px-4 py-2 text-sm sm:text-md font-semibold text-black md:rounded-full md:border-none hover:text-[#899926] transition-colors duration-100">
              Anmelden
            </button>
          </Link>
          <Link to="/demo">
            <button className="px-4 py-2 text-sm sm:text-md font-semibold text-white bg-black rounded-full flex items-center md:border-none hover:bg-gray-800 hover:text-white transition-colors duration-100 md:flex">
              Demo anfragen
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </Link>
        </div>

        {isMenuOpen && (
          <div className="absolute top-16 left-0 right-0 pt-2 bg-white shadow-md md:hidden">
            <div className="flex flex-col px-4 text-[#586979]">
              <a
                href="#faq"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection("faq");
                }}
              >
                <button className="w-full text-left py-4 text-lg font-base hover:text-[#899926] border-b border-[#E8EDF0]">
                  Häufige Fragen (FAQ)
                </button>
              </a>
              <a
                href="#price"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection("price");
                }}
              >
                <button className="w-full text-left py-4 text-lg font-base hover:text-[#899926] border-b border-[#E8EDF0]">
                  Preis
                </button>
              </a>
              <Link to="https://www.medisuite.ch/login">
                <button className="w-full text-left py-4 text-lg font-medium hover:text-[#899926]">
                  Anmelden
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
