import { useNavigate, useLocation } from "react-router-dom";
import { SIGNUP_ROUTES, SIGNUP_APPROVAL_ROUTES } from "../constants/routes";

export function useSignupNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentIndex = () => {
    if (location.pathname.startsWith("/signup-approval")) {
      return SIGNUP_APPROVAL_ROUTES.indexOf(location.pathname);
    }
    return SIGNUP_ROUTES.indexOf(location.pathname);
  };

  const getCurrentRoutes = () => {
    return location.pathname.startsWith("/signup-approval")
      ? SIGNUP_APPROVAL_ROUTES
      : SIGNUP_ROUTES;
  };

  const goToNext = () => {
    const currentRoutes = getCurrentRoutes();
    const currentIndex = getCurrentIndex();

    if (currentIndex >= 0 && currentIndex < currentRoutes.length - 1) {
      navigate(currentRoutes[currentIndex + 1]);
    }
  };

  const goToPrevious = () => {
    const currentRoutes = getCurrentRoutes();
    const currentIndex = getCurrentIndex();

    if (currentIndex === 0) {
      navigate("/");
    } else if (
      location.pathname === "/signup/success" ||
      location.pathname === "/signup/pending" ||
      location.pathname === "/signup-approval/success"
    ) {
      window.location.href = "/";
    } else if (currentIndex > 0) {
      navigate(currentRoutes[currentIndex - 1]);
    }
  };

  return {
    goToNext,
    goToPrevious,
    currentStep: getCurrentIndex() + 1,
  };
}
