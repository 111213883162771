import React, { useState } from "react";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useSignupApproval } from "../../../contexts/SignupApprovalContext";
export default function SignupApprovalFavorits() {
  const { signupData, updateSignupData } = useSignupApproval();
  const { goToNext, goToPrevious } = useSignupNavigation();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(
    signupData.favoriteProducts || [],
  );
  const [isSearching, setIsSearching] = useState(false);

  const extractProductFields = (product) => {
    return {
      _id: product._id,
      name: {
        bes1: product.name.bes1,
        bes2: product.name.bes2,
      },
      vke: product.vke,
      ids: {
        esku: product.ids?.esku,
        isku: product.ids?.isku,
      },
    };
  };

  const searchProducts = async (term) => {
    if (!term) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/products?search=${term}`,
      );
      const { data } = await response.json();
      setSearchResults(data.products);
    } catch (error) {
      console.error("Error searching products:", error);
      setSearchResults([]);
    }
    setIsSearching(false);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    searchProducts(term);
  };

  const addProduct = (product) => {
    if (!selectedProducts.find((p) => p._id === product._id)) {
      const simplifiedProduct = extractProductFields(product);
      const newSelectedProducts = [...selectedProducts, simplifiedProduct];
      setSelectedProducts(newSelectedProducts);
      updateSignupData({
        ...signupData,
        favoriteProducts: newSelectedProducts,
      });
    }
    setSearchTerm("");
    setSearchResults([]);
  };

  const removeProduct = (productId) => {
    const newSelectedProducts = selectedProducts.filter(
      (p) => p._id !== productId,
    );
    setSelectedProducts(newSelectedProducts);
    updateSignupData({
      ...signupData,
      favoriteProducts: newSelectedProducts,
    });
  };

  return (
    <SignupPageLayout
      title="Sortiment"
      step={5}
      totalSteps={7}
      progress={60}
      description="Bitte definieren Sie hier das Kundensortiment."
      footer={
        <SignupFooter
          isValid={true}
          onSubmit={goToNext}
          onBack={goToPrevious}
          submitText="Weiter"
        />
      }
    >
      <div className="space-y-4">
        {/* Search Input */}
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Produkt suchen..."
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />

          {/* Search Results Dropdown */}
          {searchResults.length > 0 && searchTerm && (
            <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
              {searchResults.map((product) => (
                <div
                  key={product._id}
                  onClick={() => addProduct(product)}
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                >
                  <div className="font-medium">{product.name.bes1}</div>
                  <div className="text-sm text-gray-600">
                    {product.name.bes2}
                  </div>
                  <div className="text-sm text-gray-500">
                    Art. {product.ids?.esku}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Selected Products List */}
        <div className="mt-4">
          {selectedProducts.map((product) => (
            <div
              key={product._id}
              className="flex justify-between items-center p-3 mb-2 bg-gray-50 rounded-md md:border"
            >
              <div>
                <div className="font-medium">{product.name.bes1}</div>
                <div className="text-sm text-gray-600">{product.name.bes2}</div>
                <div className="text-sm text-gray-500">
                  VKE: {product.vke} | SKU: {product.ids?.esku}
                </div>
              </div>
              <button
                onClick={() => removeProduct(product._id)}
                className="p-1 hover:bg-gray-200 rounded-full"
              >
                <XMarkIcon className="h-5 w-5 text-gray-500" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </SignupPageLayout>
  );
}
