import { createContext, useContext, useState } from "react";

// Create the context with a default value
const SignupContext = createContext(undefined);

export function SignupProvider({ children }) {
  const [signupData, setSignupData] = useState({
    contact: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      jobTitle: "",
    },
    institution: {
      name: "",
      street: "",
      streetNo: "",
      zip: "",
      city: "",
    },
    departments: [],
    employees: [],
  });

  const resetSignupData = () => {
    setSignupData({
      contact: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        jobTitle: "",
      },
      institution: {
        name: "",
        street: "",
        streetNo: "",
        zip: "",
        city: "",
      },
      departments: [],
      employees: [],
    });
  };

  const updateSignupData = (step, data) => {
    setSignupData((prev) => ({
      ...prev,
      [step]: data,
    }));
  };

  const value = {
    signupData,
    updateSignupData,
    resetSignupData,
  };

  return (
    <SignupContext.Provider value={value}>{children}</SignupContext.Provider>
  );
}

export function useSignup() {
  const context = useContext(SignupContext);
  if (context === undefined) {
    throw new Error("useSignup must be used within a SignupProvider");
  }
  return context;
}
