import React from "react";
import { useNavigate } from "react-router-dom";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";

export default function SignupPending() {
  const navigate = useNavigate();

  const handleStartNewSignup = () => {
    // Clear any existing pending status
    localStorage.removeItem("signupPending");
    // Navigate to the first signup page
    navigate("/signup/contact");
  };

  return (
    <SignupPageLayout>
      <div className="flex flex-col items-center justify-center min-h-full text-center space-y-6 py-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-24 w-24 text-[#aabc33]"
        >
          <path
            fillRule="evenodd"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-6h2v2h-2v-2zm0-8h2v6h-2V6z"
            clipRule="evenodd"
          />
        </svg>

        <div className="space-y-4 max-w-2xl">
          <h2 className="text-2xl font-semibold text-gray-800">
            Registrierung in Bearbeitung
          </h2>

          <div className="mt-6">
            <p className="text-gray-700">
              Sie haben bereits eine Registrierung eingereicht, die derzeit von
              unserem Team geprüft wird. Wir werden Sie in Kürze kontaktieren.
            </p>
            <p className="mt-4">
              Falls Sie sich neu registrieren möchten, können Sie dies über den
              untenstehenden Knopf tun.
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            onClick={handleStartNewSignup}
            className="text-white px-6 py-2 rounded-md transition-colors bg-black hover:bg-neutral-800"
          >
            Neue Registrierung starten
          </button>
        </div>
      </div>
    </SignupPageLayout>
  );
}
