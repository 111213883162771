import React from "react";

export default function MedisuiteStatisticsIllustration() {
  return (
    <div className="mt-8">
      <div id="main-container" className="bg-gray-100">
        <header
          id="header"
          className="bg-[#C8D65D] p-4 flex justify-between items-center"
        >
          <h1 className="text-white font-bold text-lg">
            Aktueller Jahresverbrauch
          </h1>
          <button className="text-white">
            <i className="fa-solid fa-ellipsis-vertical" />
          </button>
        </header>
        <main id="patient-list" className="bg-white shadow-md overflow-hidden">
          <div id="patient-row-1" className="p-4 border-b border-gray-200">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-semibold">Meier, Hans</span>
              <span className="text-xs text-gray-500">CHF 387.60 / 408.01</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-[#FF6666] h-2.5 rounded-full"
                style={{ width: "95%" }}
              />
            </div>
          </div>
          <div
            id="patient-row-2"
            className="p-4 border-b border-gray-200 bg-gray-50"
          >
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-semibold">Müller, Erika</span>
              <span className="text-xs text-gray-500">CHF 650.45 / 867.28</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-[#FF9900] h-2.5 rounded-full"
                style={{ width: "75%" }}
              />
            </div>
          </div>
          <div id="patient-row-3" className="p-4 border-b border-gray-200">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-semibold">Schmidt, Elisabeth</span>
              <span className="text-xs text-gray-500">CHF 204.00 / 408.01</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-[#FFCC00] h-2.5 rounded-full"
                style={{ width: "50%" }}
              />
            </div>
          </div>
          <div id="patient-row-4" className="p-4 border-b border-gray 200 bg-gray-50">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-semibold">Fischer, Werner</span>
              <span className="text-xs text-gray-500">CHF 199.50 / 798.00</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-[#99CC66] h-2.5 rounded-full"
                style={{ width: "25%" }}
              />
            </div>
          </div>
          <div id="patient-row-5" className="p-4 bg-white">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-semibold">Allenspach, Lilian</span>
              <span className="text-xs text-gray-500">leichte Inkontinenz</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-[#99CC66] h-2.5 rounded-full"
                style={{ width: "0%" }}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
