export default function SignupFooter({
  isValid,
  onSubmit,
  allowSkip = false,
  submitText = "Weiter",
  validationMsg = "Bitte füllen Sie alle Pflichtfelder aus.",
}) {
  return (
    <div className="bottom-0 left-0 right-0 px-4 pb-4 space-y-3">
      <span className="text-sm text-neutral-500 text-center">
        {!isValid && validationMsg}
      </span>
      <button
        disabled={!isValid}
        onClick={onSubmit}
        className="w-full bg-[#C8D65D] text-white py-3 rounded-lg font-medium disabled:opacity-60 disabled:cursor-not-allowed disabled:bg-neutral-300"
      >
        {submitText}
      </button>
    </div>
  );
}
