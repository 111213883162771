import React from "react";
import { Link } from "react-router-dom";

function MedisuiteFree() {
  return (
    <div
      id="price"
      className="flex flex-col lg:flex-row items-center justify-between p-6 rounded-2xl mb-12"
    >
      <div id="top-section" className="relative mb-8 lg:mb-0 flex-1 px-12">
        <img
          src="/home/images/medisuite/chf_0.png"
          alt="CHF 0"
          className="w-full h-full max-h-[250px] md:max-h-[350px] object-contain"
        />
      </div>
      <div className="lg:flex-1 w-full">
        <div className="text-left mb-8">
          <h1 className="text-3xl lg:text-4xl font-bold mb-6">
            Komplett&nbsp;kostenlos!
          </h1>
          <ul className="space-y-4">
            <li className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-green-500 mr-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#999"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span className="text-lg">Keine Einrichtungskosten</span>
            </li>
            <li className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-green-500 mr-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#999"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span className="text-lg">Keine Monatsgebühr</span>
            </li>
            <li className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-green-500 mr-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#999"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span className="text-lg">Keine Begrenzungen</span>
            </li>
          </ul>
        </div>
        <div id="bottom-section" className="text-center lg:text-left">
          <Link
            to="/demo"
            className="bg-[#C8D65D] text-white py-3 px-6 rounded-lg text-lg shadow-md shadow-black/20
            font-semibold flex items-center justify-center hover:bg-[#b3c354] transition-colors duration-300"
          >
            {" "}
            Jetzt starten
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MedisuiteFree;
