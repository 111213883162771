import React, { useState, useEffect, useRef } from "react";
import { useSignup } from "../../../contexts/SignupContext";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";
import {
  AddIcon,
  ConfirmIcon,
  EditIcon,
  XIcon,
} from "../../../components/Icons";

export default function SignupDepartments() {
  const { signupData, updateSignupData } = useSignup();
  const { goToNext, _ } = useSignupNavigation();
  const [departments, setDepartments] = useState(signupData.departments || []);
  const [editingId, setEditingId] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(40);
  const listRef = useRef(null);

  const addDepartment = () => {
    if (departments.length >= 12) return; // Prevent adding more than 12 departments
    const newDepartment = { id: Date.now(), name: "" };
    setDepartments([...departments, newDepartment]);
    setEditingId(newDepartment.id);

    setTimeout(() => {
      listRef.current?.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  };

  const updateDepartment = (id, newName) => {
    setDepartments(
      departments.map((dept) =>
        dept.id === id ? { ...dept, name: newName } : dept,
      ),
    );
  };

  const deleteDepartment = (id) => {
    setDepartments(departments.filter((dept) => dept.id !== id));
    setError(null);
  };

  const confirmEdit = () => {
    const editedDept = departments.find((dept) => dept.id === editingId);

    // Prevent saving empty names
    if (!editedDept.name.trim()) {
      setError("Bitte geben Sie einen Abteilungsnamen ein");
      return;
    }

    const isDuplicate = departments.some(
      (dept) =>
        dept.id !== editingId &&
        dept.name.toLowerCase() === editedDept.name.toLowerCase(),
    );

    if (isDuplicate) {
      setError(
        `Eine Abteilung mit dem Namen "${editedDept.name}" existiert bereits`,
      );
      return;
    }

    setError(null);
    setEditingId(null);

    // Add scroll effect after confirming edit
    setTimeout(() => {
      listRef.current?.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  };

  const handleSubmit = () => {
    updateSignupData("departments", departments);
    goToNext();
  };

  useEffect(() => {
    // Validate that we have departments and all names are non-empty
    const hasValidDepartments = departments.every(
      (dept) => dept.name.trim() !== "",
    );

    // Check for duplicate names
    const names = departments.map((dept) => dept.name.toLowerCase());
    const hasNoDuplicates = new Set(names).size === names.length;

    setIsValid(hasValidDepartments && hasNoDuplicates && !error);

    // Calculate progress percentage
    // If we have at least one department with a valid name, show 20% progress
    // Each additional valid department adds 10% up to 40%
    const validDepartments = departments.filter(
      (dept) => dept.name.trim() !== "",
    ).length;
    const baseProgress = 40;
    const additionalProgress = Math.min(validDepartments * 5, 20);

    // Use setTimeout for smooth transition
    setTimeout(() => {
      setProgressPercentage(baseProgress + additionalProgress);
    }, 500);
  }, [departments, error]);

  // Add new handler for key press
  const handleKeyPress = (e, id) => {
    if (e.key === "Enter") {
      confirmEdit();
    }
  };

  return (
    <SignupPageLayout
      title="Abteilungen"
      step={3}
      totalSteps={5}
      progress={progressPercentage}
      description={`Bitte erfassen Sie die Abteilungen Ihrer Einrichtung. Beispiele:<br />
      <ul class="list-disc list-inside my-1">
        <li>WG 1, WG 2, WG 3</li>
        <li>EG, OG, 2. OG</li>
      </ul>`}
      explainTooltip="Sie können auch ohne Abteilungen fortfahren."
      parseHtml={true}
      footer={
        <SignupFooter
          isValid={isValid}
          onSubmit={handleSubmit}
          allowSkip={true}
        />
      }
    >
      {/* Departments list */}
      <div ref={listRef} className="space-y-4">
        {departments.map((dept) => (
          <div
            key={dept.id}
            className="bg-white rounded-lg shadow md:shadow-none md:border md:border-[#E4EAEE] p-4"
          >
            <div className="flex items-center justify-between">
              {editingId === dept.id ? (
                <>
                  <input
                    type="text"
                    value={dept.name}
                    onChange={(e) => {
                      setError(null);
                      updateDepartment(dept.id, e.target.value);
                    }}
                    onKeyPress={(e) => handleKeyPress(e, dept.id)}
                    onBlur={() => confirmEdit()}
                    className={`text-lg font-semibold border-b rounded-none focus:outline-none w-full
                    ${error ? "border-red-500 focus:border-red-500 text-red-500" : "border-gray-300 focus:border-[#C8D65D]"}`}
                    placeholder="Name der Abteilung"
                    autoFocus
                  />
                </>
              ) : (
                <span
                  className="text-lg font-semibold cursor-pointer"
                  onClick={() => setEditingId(dept.id)}
                >
                  {dept.name}
                </span>
              )}
              <div className="flex items-center">
                <button
                  className={`mr-2 text-neutral-500`}
                  disabled={error}
                  onClick={() =>
                    editingId === dept.id
                      ? confirmEdit()
                      : setEditingId(dept.id)
                  }
                >
                  {editingId === dept.id ? <ConfirmIcon /> : <EditIcon />}
                </button>
                <button
                  className="text-neutral-500"
                  onClick={() => deleteDepartment(dept.id)}
                >
                  <XIcon />
                </button>
              </div>
            </div>
          </div>
        ))}

        {/* Add department button */}
        {!editingId && departments.length < 12 && (
          <div>
            <button
              className="flex items-center w-full text-neutral-500"
              onClick={addDepartment}
              tabIndex={0}
            >
              <AddIcon />
              <span className="text-lg font-semibold">
                Abteilung hinzufügen
              </span>
            </button>
          </div>
        )}
      </div>
    </SignupPageLayout>
  );
}
