import React from "react";

export default function MedisuitePrescriptionIllustration() {
  return <div >
    <section
      className="bg-white rounded-lg shadow-md overflow-hidden mb-6"
    >
      <div className="bg-[#C8D65D] text-white p-3">
        <h2 className="text-base font-semibold">VERORDNUNGEN</h2>
      </div>
      <div className="p-3">
        <div className="space-y-4">
          <div className="border-b pb-2">
            <p className="text-xs text-neutral-500">GÜLTIG AB</p>
            <p className="text-sm">01.01.2025</p>
          </div>
          <div className="border-b pb-2">
            <p className="text-xs text-neutral-500">INKONTINENZGRAD</p>
            <p className="text-sm">totale Inkontinenz</p>
          </div>
          <div className="border-b pb-2">
            <p className="text-xs text-neutral-500">ZSR-NUMMER</p>
            <p className="text-sm">U991321</p>
          </div>
          <div>
            <p className="text-xs text-neutral-500">REZEPT</p>
            <button className="mt-1 text-sm text-neutral-600">
              <div className="mx-auto flex flex-row gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
                Hochladen
              </div>
            </button>
          </div>
        </div>
      </div>
          <button
            type="submit"
            className="w-full bg-neutral-700 text-white py-2 px-4 rounded-b-md hover:bg-neutral-600~mt-2"
          >
            Neue Verordnung hinzufügen
          </button>
    </section>
    
</div>
;
}
