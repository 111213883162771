export default function SignupDescription({ description, parseHtml }) {
  if (parseHtml) {
    return (
      <div className="bg-white px-4 pb-4 border-b border-dashed border-[#E4EAEE]">
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="text-sm text-neutral-700"
        />
      </div>
    );
  }

  return (
    description && (
      <div className="bg-white px-4 pb-4 border-b border-dashed border-[#E4EAEE]">
        <p className="text-sm text-neutral-700">{description}</p>
      </div>
    )
  );
}
