import React from "react";

export default function MedisuiteDailyNeedsIllustration() {
  return (
    <div className="max-w-md mx-auto">
      <div className="bg-white shadow-md overflow-hidden rounded-lg">
        <div className="p-4 bg-[#C8D65D] text-white font-semibold text-lg flex justify-between items-center">
          <span>EINLAGEN</span>
          <div className="flex space-x-4">
            <span>07:00</span>
            <span>19:00</span>
          </div>
        </div>
        <div className="divide-y divide-gray-200">
          <div className="p-4 flex justify-between items-center bg-neutral-50">
            <div>
              <p className="font-medium">Flex Ultima Medium</p>
              <p className="text-sm text-left text-gray-600">3866 ml</p>
            </div>
            <div className="flex space-x-2">
              <input
                type="number"
                readOnly={true}
                className="w-12 h-8 border border-gray-300 rounded text-center"
                disabled
              />
              <input
                type="number"
                readOnly={true}
                className="w-12 h-8 border border-gray-300 rounded text-center"
                disabled
              />
            </div>
          </div>
          <div className="p-4 flex justify-between items-center bg-neutral-50">
            <div>
              <p className="font-medium">Flex Ultima Large</p>
              <p className="text-sm text-left text-gray-600">4257 ml</p>
            </div>
            <div className="flex space-x-2">
              <input
                type="number"
                readOnly={true}
                className="w-12 h-8 border border-gray-300 rounded text-center"
                disabled
              />
              <input
                type="number"
                readOnly={true}
                className="w-12 h-8 border border-gray-300 rounded text-center"
                disabled
              />
            </div>
          </div>
          <div className="p-4 flex justify-between items-center bg-blue-50">
            <div>
              <p className="font-medium">Slip Maxi+ Medium </p>
              <p className="text-sm text-left text-gray-600">3600 ml</p>
            </div>
            <div className="flex space-x-2">
              <input
                type="number"
                readOnly={true}
                defaultValue={1}
                className="w-12 h-8 border border-gray-300 rounded text-center"
                disabled
              />
              <input
                type="number"
                readOnly={true}
                className="w-12 h-8 border border-gray-300 rounded text-center"
                disabled
              />
            </div>
          </div>
          <div className="p-4 flex justify-between items-center bg-blue-50">
            <div>
              <p className="font-medium">Slip Maxi+ Large</p>
              <p className="text-sm text-left text-gray-600">4200 ml</p>
            </div>
            <div className="flex space-x-2">
              <input
                type="number"
                readOnly={true}
                className="w-12 h-8 border border-gray-300 rounded text-center"
                disabled
              />
              <input
                type="number"
                readOnly={true}
                defaultValue={1}
                className="w-12 h-8 border border-gray-300 rounded text-center"
                disabled
              />
            </div>
          </div>
          <div className="p-4 flex justify-between items-center bg-neutral-50">
            <div>
              <p className="font-medium">Slip Maxi+ X-Large</p>
              <p className="text-sm text-left text-gray-600">4400 ml</p>
            </div>
            <div className="flex space-x-2">
              <input
                type="number"
                readOnly={true}
                className="w-12 h-8 border border-gray-300 rounded text-center"
                disabled
              />
              <input
                type="number"
                readOnly={true}
                className="w-12 h-8 border border-gray-300 rounded text-center"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
