import React, { useState } from "react";

export default function MedisuiteFAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (sectionIndex, itemIndex) => {
    const compositeIndex = `${sectionIndex}-${itemIndex}`;
    setOpenIndex(openIndex === compositeIndex ? null : compositeIndex);
  };

  const faqSections = [
    {
      title: "Allgemeine Fragen",
      items: [
        {
          question: "Was ist Medisuite?",
          answer:
            "Medisuite ist eine Web-App für die Verwaltung von Inkontinenzprodukten in Pflege\u00ADeinrichtungen. Es bietet Funktionen wie Bewohner\u00ADverwaltung, Verordnungs\u00ADmanagement, Bestell\u00ADautomatisierung und Abrechnung mit Krankenkassen.",
        },
        {
          question: "Wer steckt dahinter?",
          answer:
            "Medisuite ist ein Angebot der Scheitlin Papier AG, ein Schweizer Grosshandels­unternehmen im Familien­besitz der zweiten Generation. Die App wurde speziell für Heime und Spitex-Organisationen entwickelt, um die Arbeit im Pflegesektor zu erleichtern.",
        },
        {
          question: "Wie kann ich loslegen?",
          answer:
            "Klicken Sie oben rechts auf 'Demo anfragen', füllen Sie das Formular aus und einer unserer Pflegeexperten meldet sich bei Ihnen für eine persönliche Einführung und Mitarbeiterschulung.",
        },
      ],
    },
    {
      title: "Logistische Fragen",
      items: [
        {
          question:
            "Wie erkenne ich bei der Lieferung, welche Einlagen wem gehören?",
          answer:
            "Jeder Karton oder Beutel wird von uns mit einem Etikett versehen, auf dem die Abteilung und der Bewohner eindeutig gekennzeichnet sind. So verteilen Sie die Einlagen schnell und einfach in Ihrer Einrichtung.",
        },
        {
          question: "Kann die Lieferung auf Rollwagen erfolgen?",
          answer:
            "Ja, auf Wunsch können wir gerne auch auf Rollwagen/Versorungswagen liefern.",
        },
        {
          question: "In welchem Zyklus kann bestellt/geliefert werden?",
          answer:
            "Sie bestimmen den Bestellrhythmus – die meisten Kunden bestellen alle 3 bis 4 Wochen. Wir vereinbaren mit Ihnen feste Bestell- und Liefertage, z. B. Bestellung von Montag bis Mittwoch und Lieferung am Freitag.",
        },
      ],
    },
    {
      title: "Technische Fragen",
      items: [
        {
          question: "Gibt es technische Voraussetzungen?",
          answer:
            "Medisuite läuft in jedem modernen Webbrowser. Sie benötigen lediglich einen Computer oder Tablet mit Internetverbindung.",
        },
        {
          question: "Benötige ich eine Schnittstelle zu Medisuite?",
          answer:
            "Nein, es sind keine Schnittstellen notwendig. Nach Ihrer Anmeldung bei uns werden Sie von einer unserer Pflegefachpersonen kontaktiert und können sofort loslegen.",
        },
        {
          question: "Sind meine Daten sicher?",
          answer:
            "Ja, die Sicherheit Ihrer Daten ist uns ein besonderes Anliegen! Wir sind stets bestrebt, Ihre sensiblen Informationen nach bestem Wissen und Gewissen zu schützen und die geltenden Datenschutz\u00ADbestimmungen einzuhalten. Ihre Daten werden vertraulich behandelt und zweck\u00ADentsprechend verwendet. Bei Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.",
        },
      ],
    },
  ];

  return (
    <div id="faq-page" className="flex flex-col mb-4 mx-auto">
      <div id="header" className="px-4 py-6 text-center">
        <h1 className="text-2xl sm:text-3xl font-medium text-center mb-4 text-gray-800">
          Häufig gestellte Fragen
        </h1>
        <p className="text-gray-600 text-base min-[365px]:text-sm sm:text-xl md:text-xl">
          Finden Sie Antworten auf die häufigsten Fragen
        </p>
      </div>
      <div id="faq" className="mt-4 mb-4">
        {faqSections.map((section, sectionIndex) => (
          <div key={sectionIndex} className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              {section.title}
            </h2>
            {section.items.map((item, itemIndex) => (
              <div
                key={itemIndex}
                className="bg-white rounded-lg shadow-md mb-4 overflow-hidden"
              >
                <button
                  className="w-full text-left p-4 focus:outline-none"
                  onClick={() => toggleAccordion(sectionIndex, itemIndex)}
                >
                  <div className="flex justify-between items-center">
                    <span className="font-medium text-gray-800 text-base min-[365px]:text-lg">
                      {item.question}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`h-5 w-5 text-gray-500 transition-transform duration-300 ${
                        openIndex === `${sectionIndex}-${itemIndex}`
                          ? "transform rotate-180"
                          : ""
                      }`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </button>
                <div
                  className={`p-4 bg-gray-50 text-base min-[365px]:text-md md:text-lg text-gray-700 border-t border-gray-100 ${
                    openIndex === `${sectionIndex}-${itemIndex}`
                      ? "block"
                      : "hidden"
                  }`}
                >
                  {item.answer}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
