import React, { useState, useEffect } from "react";
import TurnstileCaptcha from "../../../components/common/TurnstileCaptcha";
import { Link } from "react-router-dom";

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const formatPhoneNumber = (phone) => {
  const digits = phone.replace(/\D/g, "");

  if (digits.length <= 10) {
    return digits
      .replace(/(\d{3})(\d{3})?(\d{2})?(\d{2})?/, function (_, p1, p2, p3, p4) {
        let parts = [p1];
        if (p2) parts.push(p2);
        if (p3) parts.push(p3);
        if (p4) parts.push(p4);
        return parts.join(" ");
      })
      .trim();
  }
  return digits;
};

export default function DemoRequest() {
  const [formData, setFormData] = useState({
    institution: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });
  const [isValid, setIsValid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [emailValidationTimeout, setEmailValidationTimeout] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;
    if (name === "email") {
      formattedValue = value.toLowerCase();
    } else if (name === "phone") {
      formattedValue = formatPhoneNumber(value);
    }

    setFormData((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));

    if (emailValidationTimeout) {
      clearTimeout(emailValidationTimeout);
    }

    if (name === "email") {
      const newTimeout = setTimeout(() => {
        if (value && !isValidEmail(value)) {
          setEmailError("Bitte geben Sie eine gültige E-Mail-Adresse ein");
        } else {
          setEmailError("");
        }
      }, 500);

      setEmailValidationTimeout(newTimeout);
    }
  };

  useEffect(() => {
    return () => {
      if (emailValidationTimeout) {
        clearTimeout(emailValidationTimeout);
      }
    };
  }, [emailValidationTimeout]);

  useEffect(() => {
    const { institution, firstname, lastname, email, phone } = formData;

    // phone must be at least 10 digits
    const isValidPhone = phone.length >= 10;

    setIsValid(
      institution &&
        firstname &&
        lastname &&
        email &&
        isValidEmail(email) &&
        isValidPhone,
    );
  }, [formData]);

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid || isSubmitting || !captchaToken) return;

    setIsSubmitting(true);
    setSubmitError("");

    // Restructure the data to match the schema
    const requestData = {
      contact: {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        phone: formData.phone,
      },
      institution: {
        name: formData.institution,
      },
      captchaToken,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/demo-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData), // Send the restructured data
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Server error");
      }

      const data = await response.json();
      setSubmitSuccess(true);
    } catch (error) {
      console.error("Submission error:", error);
      setSubmitError(
        error.message ||
          "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  if (submitSuccess) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-16 flex flex-col items-center justify-center gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-24 w-24 text-[#aabc33]"
        >
          <path
            fillRule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
            clipRule="evenodd"
          />
        </svg>
        <div className="text-center space-y-4">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Vielen Dank für Ihre Anfrage!
          </h2>
          <p className="text-gray-600">
            Wir werden uns in Kürze mit Ihnen in Verbindung setzen.
          </p>
          <p className="text-gray-600">
            Sie dürfen dieses Fenster nun schliessen.
          </p>
          <Link
            to="/"
            className="inline-flex items-center text-gray-600 hover:text-[#aabc33] transition-colors mb-6"
          >
            Zurück zur Startseite
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto px-4 py-16">
      <div className="mb-8">
        <Link
          to="/"
          className="inline-flex items-center text-gray-600 hover:text-[#aabc33] transition-colors mb-6"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
          Zurück
        </Link>

        <h1 className="text-2xl font-bold text-gray-900 mb-4">Demo anfragen</h1>
        <p className="text-gray-600">
          Bitte füllen Sie das Formular aus, um eine kostenlose Vorführung
          anzufragen.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="institution"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Firma <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="institution"
            name="institution"
            value={formData.institution}
            onChange={handleChange}
            className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
            placeholder="Firmenname"
          />
        </div>

        <div className="flex space-x-4">
          <div className="w-1/2">
            <label
              htmlFor="firstname"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Vorname <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
              placeholder="Vorname"
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="lastname"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Nachname <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
              placeholder="Nachname"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            E-Mail <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`w-full px-2 py-2 border-b ${
              emailError ? "border-red-500" : "border-gray-300"
            } focus:border-[#aabc33] focus:outline-none transition-all rounded-none`}
            placeholder="ihre@email.ch"
          />
          {emailError && (
            <p className="mt-1 text-sm text-red-500">{emailError}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Telefon <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
            placeholder="079 123 45 67"
            pattern="[0-9\s]*"
            inputMode="numeric"
            minLength={10}
          />
        </div>

        <TurnstileCaptcha
          onVerify={handleCaptchaVerify}
          onError={() => setCaptchaToken(null)}
          onExpire={() => setCaptchaToken(null)}
          justify="start"
        />

        <div>
          <span className="text-sm text-neutral-500 mt-0 mb-2 block">
            {!isValid && "Bitte füllen Sie alle Pflichtfelder aus."}
            {isValid && !captchaToken && "Bitte lösen Sie den Captcha."}
          </span>
          <button
            type="submit"
            disabled={!isValid || isSubmitting || !captchaToken}
            className={`w-full py-3 px-4 text-white rounded-md transition-all ${
              isValid && !isSubmitting && captchaToken
                ? "bg-[#aabc33] hover:bg-[#99aa2e]"
                : "bg-gray-300 cursor-not-allowed"
            }`}
          >
            {isSubmitting ? "Wird gesendet..." : "Absenden"}
          </button>
        </div>
      </form>
    </div>
  );
}
