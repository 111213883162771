const MedisuiteSliderSection = ({
  title,
  subtitle,
  description,
  illustration,
}) => (
  <div className="hero__image_and_button p-6 bg-white rounded-lg border border-[E4EAEE] mb-4 mx-auto min-[900px]:flex min-[900px]:items-center min-[900px]:gap-8 min-[900px]:px-24">
    <div className="min-[900px]:w-1/2 mb-4 space-y-2 min-[900px]:space-y-4">
      <h2 className="text-xl min-[900px]:text-2xl font-bold text-center min-[900px]:text-left">
        {title}
      </h2>
      <p className="text-center min-[900px]:text-left font-medium">
        {subtitle}
      </p>
      <p className="text-center min-[900px]:text-left min-[900px]:mb-0">
        {description}
      </p>
    </div>
    <div className="relative min-[900px]:w-1/2">{illustration}</div>
  </div>
);

export default MedisuiteSliderSection;
