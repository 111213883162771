import React, { useState, useEffect } from "react";
import { useSignup } from "../../../contexts/SignupContext";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";
import SignupDescription from "../../../components/signup/SignupDescription";

// Add this email validation function at the top of the file, before the component
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Add this phone formatting function near the top with isValidEmail
const formatPhoneNumber = (phone) => {
  // Remove all non-digit characters
  const digits = phone.replace(/\D/g, "");

  // Format as 000 000 00 00
  if (digits.length <= 10) {
    return digits
      .replace(/(\d{3})(\d{3})?(\d{2})?(\d{2})?/, function (_, p1, p2, p3, p4) {
        let parts = [p1];
        if (p2) parts.push(p2);
        if (p3) parts.push(p3);
        if (p4) parts.push(p4);
        return parts.join(" ");
      })
      .trim();
  }
  return digits;
};

export default function SignupContact() {
  const { signupData, updateSignupData } = useSignup();
  const { goToNext, _ } = useSignupNavigation();
  const [formData, setFormData] = useState(signupData.contact);
  const [isValid, setIsValid] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [emailError, setEmailError] = useState("");
  const [emailValidationTimeout, setEmailValidationTimeout] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Format value based on field type
    let formattedValue = value;
    if (name === "email") {
      formattedValue = value.toLowerCase();
    } else if (name === "phone") {
      formattedValue = formatPhoneNumber(value);
    }

    setFormData((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));

    // Clear previous timeout
    if (emailValidationTimeout) {
      clearTimeout(emailValidationTimeout);
    }

    // Set new timeout for email validation
    if (name === "email") {
      const newTimeout = setTimeout(() => {
        if (value && !isValidEmail(value)) {
          setEmailError("Bitte geben Sie eine gültige E-Mail-Adresse ein");
        } else {
          setEmailError("");
        }
      }, 500); // Validate after 500ms of no typing

      setEmailValidationTimeout(newTimeout);
    }
  };

  // Clean up timeout on component unmount
  useEffect(() => {
    return () => {
      if (emailValidationTimeout) {
        clearTimeout(emailValidationTimeout);
      }
    };
  }, [emailValidationTimeout]);

  useEffect(() => {
    const { firstname, lastname, email, phone, jobTitle } = formData;

    setIsValid(
      firstname &&
        lastname &&
        email &&
        isValidEmail(email) &&
        phone &&
        jobTitle,
    );

    const filledFields = [firstname, lastname, email, phone, jobTitle].filter(
      (field) => field && field.trim() !== "",
    ).length;

    setProgressPercentage(filledFields * 4);
  }, [formData]);

  const handleSubmit = () => {
    if (isValid) {
      updateSignupData("contact", formData);
      goToNext();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && isValid) {
      handleSubmit();
    }
  };

  // Add this handler function alongside your other handlers
  const handleBlur = (e) => {
    const { value } = e.target;
    if (value && !isValidEmail(value)) {
      setEmailError("Bitte geben Sie eine gültige E-Mail-Adresse ein");
    } else {
      setEmailError("");
    }
  };

  return (
    <SignupPageLayout
      title="Kontaktdaten"
      step={1}
      totalSteps={5}
      progress={progressPercentage}
      description="Bitte erfassen Sie Ihre Kontaktdaten."
      explainTooltip="Kontaktieren Sie uns bitte bei Schwierigkeiten unter info@scheitlin-medical.ch."
      parseHtml={true}
      footer={<SignupFooter isValid={isValid} onSubmit={handleSubmit} />}
    >
      <div className="space-y-6">
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label
              htmlFor="firstname"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Vorname <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
              placeholder="Vorname"
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="lastname"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Nachname <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
              placeholder="Nachname"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            E-Mail <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`w-full px-2 py-2 border-b ${
              emailError ? "border-red-500" : "border-gray-300"
            } focus:border-[#aabc33] focus:outline-none transition-all rounded-none`}
            placeholder="ihre@email.ch"
          />
          {emailError && (
            <p className="mt-1 text-sm text-red-500">{emailError}</p>
          )}
        </div>
        <div>
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Telefon <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
            placeholder="079 123 45 67"
            pattern="[0-9\s]*"
            inputMode="numeric"
          />
        </div>
        <div>
          <label
            htmlFor="jobTitle"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Ihre Abteilung <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="jobTitle"
            name="jobTitle"
            value={formData.jobTitle}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className="w-full px-2 py-2 border-b border-gray-300 focus:border-[#aabc33] focus:outline-none transition-all rounded-none"
            placeholder="z.B. Geschäftsleitung, Administration, Pflegedienst ..."
          />
        </div>
      </div>
    </SignupPageLayout>
  );
}
