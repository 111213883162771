import React, { createContext, useContext, useState } from "react";

const SignupApprovalContext = createContext();

export function SignupApprovalProvider({ children }) {
  const [signupData, setSignupData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSignupData = async (signupId) => {
    if (!signupId) return;

    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/signup/${signupId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
          credentials: "omit",
        },
      );

      if (!response.ok) {
        if (response.status === 404) {
          throw new Error("Dieser Link existiert nicht.");
        } else if (response.status === 400) {
          throw new Error("Dieser Link ist abgelaufen.");
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { data } = await response.json();
      setSignupData(data);
      setError(null);
    } catch (error) {
      console.error("Error fetching signup data:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateSignupData = (newData) => {
    setSignupData(newData);
  };

  return (
    <SignupApprovalContext.Provider
      value={{
        signupData,
        isLoading,
        error,
        fetchSignupData,
        updateSignupData,
      }}
    >
      {children}
    </SignupApprovalContext.Provider>
  );
}

export function useSignupApproval() {
  const context = useContext(SignupApprovalContext);
  if (context === undefined) {
    throw new Error(
      "useSignupApproval must be used within a SignupApprovalProvider",
    );
  }
  return context;
}
