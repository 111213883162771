import React, { useState, useEffect } from "react";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import { useSignupApproval } from "../../../contexts/SignupApprovalContext";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../../utils/classNames";

export default function SignupApprovalRepresentative() {
  const { signupData, updateSignupData } = useSignupApproval();
  const { goToNext, goToPrevious } = useSignupNavigation();
  const [representatives, setRepresentatives] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRepresentatives = async () => {
      const cachedData = localStorage.getItem("representatives");
      if (cachedData) {
        const parsed = JSON.parse(cachedData);
        setRepresentatives(parsed);
        if (signupData.representative) {
          const savedRep = parsed.find(
            (rep) => rep._id === signupData.representative._id,
          );
          if (savedRep) {
            setSelected(savedRep);
          }
        }
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/sales-representatives`,
        );
        if (!response.ok) throw new Error("Failed to fetch representatives");
        const { success, data } = await response.json();
        if (!success) throw new Error("API request was not successful");

        localStorage.setItem("representatives", JSON.stringify(data));
        setRepresentatives(Array.isArray(data) ? data : []);
      } catch (err) {
        setError(err.message);
        console.error("Fetch error:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRepresentatives();
  }, [signupData.representative]);

  const handleSubmit = () => {
    if (selected) {
      updateSignupData({
        ...signupData, // Spread existing signup data
        representative: selected,
      });
      goToNext();
    }
  };

  return (
    <SignupPageLayout
      title="Aussendienst"
      step={4}
      totalSteps={7}
      progress={45}
      description="Bitte definieren Sie den verantwortlichen Aussendienst."
      footer={
        <SignupFooter
          isValid={!!selected}
          onSubmit={handleSubmit}
          onBack={goToPrevious}
          submitText="Weiter"
        />
      }
    >
      <div className="space-y-4">
        {isLoading && (
          <div className="text-center py-4">
            <div className="animate-pulse">Laden...</div>
          </div>
        )}

        {error && <div className="text-red-600 text-center py-4">{error}</div>}

        {!isLoading && !error && (
          <RadioGroup
            value={selected}
            onChange={(value) => {
              setSelected(value);
              updateSignupData({
                ...signupData, // Spread existing signup data
                representative: value,
              });
            }}
          >
            <div className="space-y-3">
              {representatives.map((representative) => (
                <RadioGroup.Option
                  key={representative._id || representative.id}
                  value={representative}
                  className={({ active, checked }) =>
                    classNames(
                      active ? "ring-2 ring-black ring-offset-2" : "",
                      checked
                        ? "bg-black text-white hover:bg-gray-900"
                        : "bg-white hover:bg-gray-50",
                      "relative block cursor-pointer rounded-lg px-6 py-4 shadow-sm focus:outline-none md:border",
                    )
                  }
                >
                  {({ checked }) => (
                    <>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label
                              as="p"
                              className={classNames(
                                checked ? "text-white" : "text-gray-900",
                                "font-medium",
                              )}
                            >
                              {representative.firstName}{" "}
                              {representative.lastName}
                              {representative.name}
                              {!representative.name &&
                                !representative.firstName &&
                                "Unnamed Representative"}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={
                                checked ? "text-white" : "text-gray-500"
                              }
                            >
                              <span>
                                {representative.region ||
                                  representative.area ||
                                  representative.territory ||
                                  ""}
                              </span>
                            </RadioGroup.Description>
                          </div>
                        </div>
                        {checked && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        )}
      </div>
    </SignupPageLayout>
  );
}
