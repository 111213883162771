import { Navigate } from "react-router-dom";
import { useSignup } from "../contexts/SignupContext";
import { SIGNUP_ROUTES } from "../constants/routes";

export default function ProtectedSignupRoute({ children }) {
  const { signupData } = useSignup();
  const currentPath = window.location.pathname;
  const currentIndex = SIGNUP_ROUTES.indexOf(currentPath);

  // Check if previous steps are completed
  const isValidProgress = () => {
    // Always allow access to the first step
    if (currentIndex === 0) return true;

    // Check if contact info is filled for steps after contact
    if (currentIndex > 0) {
      const { firstname, lastname, email, phone } = signupData.contact;
      if (!firstname || !lastname || !email || !phone) return false;
    }

    // Check if business info is filled for steps after business
    if (currentIndex > 1) {
      const { name, street, zip, city } = signupData.institution;
      if (!name || !street || !zip || !city) return false;
    }

    return true;
  };

  return isValidProgress() ? (
    children
  ) : (
    <Navigate to={SIGNUP_ROUTES[0]} replace />
  );
}
