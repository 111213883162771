import React from "react";

function MedisuiteOrderIllustration() {
  return (
    <section className="bg-white shadow rounded-lg">
      <div className="grid grid-cols-3 gap-2 p-4 border-b text-md font-semibold bg-[#C8D65D] text-white rounded-t-lg">
        <div>BEDARF</div>
        <div>LAGER</div>
        <div>BESTELLEN</div>
      </div>
      <div className="border-b p-3">
        <div className="font-semibold mb-2">Müller, Karin</div>
        <div className="flex items-center mb-3">
          <div className="w-10 h-10 bg-gray-200 rounded mr-2 flex-shrink-0">
            <div className="w-full h-full flex items-center justify-center text-xs bg-gray-500 text-white rounded">
              Form
            </div>
          </div>
          <div className="flex-grow">
            <div className="text-sm">Suprem Form Maxi</div>
            <div className="text-xs text-gray-500">LSFM5171</div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 text-sm">
          <div className="flex items-center">2 Pack</div>
          <div>
            <input
              type="text"
              className="w-full border rounded px-2 py-1"
              placeholder="Pack"
              disabled
            />
          </div>
          <div>
            <input
              type="text"
              className="w-full border rounded px-2 py-1"
              defaultValue={"2 Pack"}
              placeholder="Pack"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="border-b p-3">
        <div className="font-semibold mb-2">Huber, Bruno</div>
        <div className="flex items-center mb-3">
          <div className="w-10 h-10 bg-gray-200 rounded mr-2 flex-shrink-0">
            <div className="w-full h-full flex items-center justify-center text-xs bg-gray-500 text-white rounded">
              Slip
            </div>
          </div>
          <div className="flex-grow">
            <div className="text-sm">Slip Large Maxi+</div>
            <div className="text-xs text-gray-500">11036100</div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 text-sm">
          <div className="flex items-center">3 Pack</div>
          <div>
            <input
              type="text"
              className="w-full border rounded px-2 py-1"
              defaultValue={"2 Pack"}
              placeholder="Pack"
              disabled
            />
          </div>
          <div>
            <input
              type="text"
              className="w-full border rounded px-2 py-1"
              defaultValue={"1 Pack"}
              placeholder="Pack"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="border-b p-3">
        <button className="w-full bg-neutral-700 hover:bg-neutral-600 text-white py-3 rounded-lg font-semibold">
          In den Warenkorb legen
        </button>
      </div>
    </section>
  );
}

export default MedisuiteOrderIllustration;
