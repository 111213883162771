import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import { useSignupApproval } from "../../../contexts/SignupApprovalContext";
import SignupApprovalError from "./SignupApprovalError";
export default function SignupApprovalOverview() {
  const [searchParams] = useSearchParams();
  const { signupData, isLoading, fetchSignupData, error } = useSignupApproval();
  const { goToNext } = useSignupNavigation();

  useEffect(() => {
    const signupId = searchParams.get("id");
    fetchSignupData(signupId);
  }, [searchParams.get("id")]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!signupData) {
    return <SignupApprovalError error={error} />;
  }

  return (
    <SignupPageLayout
      title="Übersicht"
      step={1}
      totalSteps={7}
      progress={0}
      showBackButton={false}
      description="Bitte überprüfen Sie die Registrierungsdaten."
      footer={
        <SignupFooter
          isValid={true}
          onSubmit={goToNext}
          submitText="Kundenaufschaltung beginnen"
        />
      }
    >
      <div className="space-y-2">
        {/* Contact Information */}
        <div className="bg-white shadow p-4">
          <div className="flex justify-between items-start">
            <h3 className="font-semibold">Kontaktdaten</h3>
          </div>
          <div className="space-y-1 text-sm">
            <p>{`${signupData.contact.firstname} ${signupData.contact.lastname}`}</p>
            <p>{signupData.contact.email}</p>
            <p>{signupData.contact.phone}</p>
            <p>{signupData.contact.jobTitle}</p>
          </div>
        </div>

        {/* Business Information */}
        <div className="bg-white shadow p-4">
          <div className="flex justify-between items-start">
            <h3 className="font-semibold">Adresse</h3>
          </div>
          <div className="space-y-1 text-sm">
            <p>{signupData.institution.name}</p>
            <p>
              {signupData.institution.street} {signupData.institution.streetNo}
            </p>
            <p>
              {signupData.institution.zip} {signupData.institution.city}
            </p>
          </div>
        </div>

        {/* Departments */}
        {signupData.departments?.length > 0 && (
          <div className="bg-white shadow p-4">
            <div className="flex justify-between items-start">
              <h3 className="font-semibold">Abteilungen</h3>
            </div>
            <div className="space-y-1 text-sm">
              {signupData.departments.map((dept, index) => (
                <p key={dept.id}>{dept.name}</p>
              ))}
            </div>
          </div>
        )}

        {/* Employees */}
        {signupData.employees?.length > 0 && (
          <div className="bg-white shadow p-4">
            <div className="flex justify-between items-start">
              <h3 className="font-semibold">Mitarbeiter</h3>
            </div>
            <div className="space-y-3 text-sm">
              {signupData.employees.map((employee, index) => (
                <div
                  key={`${employee.id}-${index}`}
                  className="border-b pb-2 last:border-b-0 last:pb-0"
                >
                  <p className="font-medium">
                    {employee.groupname
                      ? `${employee.groupname} ${employee.firstname} ${employee.lastname}`
                      : `${employee.firstname} ${employee.lastname}`}
                  </p>
                  <p>{employee.email}</p>
                  {employee.rights.includes("can_access_all_departments") && (
                    <p className="text-gray-500">
                      {signupData.departments
                        .filter((dept) =>
                          employee.departments?.includes(String(dept.id)),
                        )
                        .map((dept) => dept.name)
                        .join(", ")}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </SignupPageLayout>
  );
}
