import React from "react";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import { useSignupApproval } from "../../../contexts/SignupApprovalContext";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../../utils/classNames";

const companyTypes = [
  {
    id: "nursing_home",
    title: "Alters- und Pflegeheim",
    description: "Lieferung erfolgt an die Adresse des Heims",
  },
  {
    id: "spitex",
    title: "Spitex",
    description:
      "Lieferung erfolgt an die Adresse des Endkunden mit Preiszuschlag auf Listenpreis",
  },
];

export default function SignupApprovalCompanyType() {
  const { signupData, updateSignupData } = useSignupApproval();
  const { goToNext, goToPrevious } = useSignupNavigation();
  const [selected, setSelected] = React.useState(
    signupData.companyType || null,
  );

  const handleSubmit = () => {
    if (selected) {
      updateSignupData({
        ...signupData,
        companyType: selected,
      });
      goToNext();
    }
  };

  return (
    <SignupPageLayout
      title="Unternehmenstyp"
      step={3}
      totalSteps={7}
      progress={30}
      description="Bitte wählen Sie Ihren Unternehmenstyp."
      footer={
        <SignupFooter
          isValid={!!selected}
          onSubmit={handleSubmit}
          onBack={goToPrevious}
          submitText="Weiter"
        />
      }
    >
      <div className="space-y-4">
        <RadioGroup value={selected} onChange={setSelected}>
          <div className="space-y-3">
            {companyTypes.map((type) => (
              <RadioGroup.Option
                key={type.id}
                value={type.id}
                className={({ active, checked }) =>
                  classNames(
                    active ? "ring-2 ring-black ring-offset-2" : "",
                    checked
                      ? "bg-black text-white hover:bg-gray-900"
                      : "bg-white hover:bg-gray-50",
                    "relative block cursor-pointer rounded-lg px-6 py-4 shadow-sm focus:outline-none md:border",
                  )
                }
              >
                {({ checked }) => (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={classNames(
                            checked ? "text-white" : "text-gray-900",
                            "font-medium",
                          )}
                        >
                          {type.title}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={checked ? "text-white" : "text-gray-500"}
                        >
                          {type.description}
                        </RadioGroup.Description>
                      </div>
                    </div>
                    {checked && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </SignupPageLayout>
  );
}
