import React, { useState } from "react";
import SignupPageLayout from "../../../components/layouts/SignupPageLayout";
import SignupFooter from "../../../components/signup/SignupFooter";
import { useSignupNavigation } from "../../../hooks/useSignupNavigation";
import { useSignupApproval } from "../../../contexts/SignupApprovalContext";
import { RefreshIcon } from "../../../components/Icons";
import { germanWords } from "../../../constants/germanWords";

export default function SignupApprovalFinal() {
  const { goToNext, goToPrevious } = useSignupNavigation();
  const { signupData, updateSignupData } = useSignupApproval();
  console.log(signupData);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Password generation function
  function generatePassword() {
    // Pick 2 random words and a 2-digit number
    const word1 = germanWords[Math.floor(Math.random() * germanWords.length)];
    const word2 = germanWords[Math.floor(Math.random() * germanWords.length)];
    const number = Math.floor(Math.random() * 90 + 10); // 10-99

    // Combine with a special character
    const specialChars = "!$@";
    const specialChar =
      specialChars[Math.floor(Math.random() * specialChars.length)];

    return `${word1}${word2}${specialChar}${number}`;
  }
  // Initialize passwords if they don't exist
  React.useEffect(() => {
    if (!signupData.employees?.some((emp) => emp.password)) {
      const employeesWithPasswords = (signupData.employees || []).map(
        (emp) => ({
          ...emp,
          password: generatePassword(),
        }),
      );

      updateSignupData({
        ...signupData,
        employees: employeesWithPasswords,
        contact: {
          ...signupData.contact,
          password: signupData.contact?.password || generatePassword(),
        },
      });
    }
  }, []);

  // Update single employee password
  const regeneratePassword = (employeeId) => {
    const updatedEmployees = signupData.employees.map((emp) =>
      emp._id === employeeId ? { ...emp, password: generatePassword() } : emp,
    );

    updateSignupData({
      ...signupData,
      employees: updatedEmployees,
    });
  };

  // Update contact password
  const regenerateContactPassword = () => {
    updateSignupData({
      ...signupData,
      contact: {
        ...signupData.contact,
        password: generatePassword(),
      },
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/signup/approval/${signupData._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
          credentials: "omit",
          body: JSON.stringify(signupData),
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.success) {
        goToNext();
      } else {
        throw new Error(data.message || "Failed to complete signup approval");
      }
    } catch (error) {
      console.error("Error completing signup approval:", error);
      // You might want to add error handling UI here
    } finally {
      setIsSubmitting(false);
    }
  };

  console.log(signupData);
  return (
    <SignupPageLayout
      title="Zugangsdaten erstellen"
      step={7}
      totalSteps={7}
      progress={100}
      description="Zugangdaten erstellen und dem Aussendienst mailen"
      footer={
        <SignupFooter
          isValid={true}
          onSubmit={handleSubmit}
          onBack={goToPrevious}
          submitText={isSubmitting ? "Wird gesendet..." : "Logins erstellen"}
          disabled={isSubmitting}
        />
      }
    >
      <div className="space-y-4">
        {/* Contact person card */}
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <span className="text-lg font-semibold">
                {signupData.contact?.firstname} {signupData.contact?.lastname}
              </span>
              <div className="text-sm mb-1 mt-2">
                <span className="font-medium">E-Mail: </span>
                {signupData.contact?.email}
              </div>
              <div className="text-sm flex items-center space-x-2">
                <span className="font-medium">Passwort: </span>
                <div className="font-mono bg-gray-100 px-2 py-1 rounded">
                  {signupData.contact?.password}
                </div>
                <button
                  onClick={regenerateContactPassword}
                  className="text-neutral-500 hover:text-neutral-700"
                  title="Neues Passwort generieren"
                >
                  <RefreshIcon />
                </button>
              </div>
            </div>
            <div className="bg-[#C8D65D] text-white px-3 py-1 rounded-full text-sm">
              Admin
            </div>
          </div>
        </div>

        {/* Employee list with passwords */}
        {signupData.employees.map((employee, index) => (
          <div
            key={`${employee._id}`}
            className="bg-white rounded-lg shadow p-4"
          >
            <div className="flex items-center justify-between">
              <div>
                <span className="text-lg font-semibold">
                  {employee.type === "group"
                    ? employee.groupname
                    : `${employee.firstname} ${employee.lastname}`}
                </span>
                <div className="text-sm mb-1 mt-2">
                  <span className="font-medium">E-Mail: </span>
                  {employee.email}
                </div>
                <div className="text-sm flex items-center space-x-2">
                  <span className="font-medium">Passwort: </span>
                  <div className="font-mono bg-gray-100 px-2 py-1 rounded">
                    {employee.password}
                  </div>
                  <button
                    onClick={() => regeneratePassword(employee._id)}
                    className="text-neutral-500 hover:text-neutral-700"
                    title="Neues Passwort generieren"
                  >
                    <RefreshIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </SignupPageLayout>
  );
}
