import React from "react";

function MedisuiteStationsIllustration() {
  return (
    <div className="max-w-md mx-auto">
      <div className="bg-neutral-100 flex flex-col rounded-lg mb-4">
        <header id="header" className="p-4 bg-[#C8D65D] shadow-sm rounded-t-lg">
          <div className="flex items-center justify-between">
            <h1 className="text-xl font-bold text-white">Abteilungen</h1>
            <button className="p-2 bg-neutral-100 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
          </div>
        </header>
        <div className="flex-grow p-4 space-y-4 overflow-y-auto">
          <div id="station-1" className="bg-white rounded-lg shadow p-4">
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-lg font-semibold">Wohngruppe A</h2>
              <button className="text-neutral-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="1" />
                  <circle cx="12" cy="5" r="1" />
                  <circle cx="12" cy="19" r="1" />
                </svg>
              </button>
            </div>
            <div className="flex items-center space-x-2 mb-3">
              <div className="w-6 h-6 rounded-full bg-neutral-200 flex items-center justify-center text-xs font-semibold">
                JD
              </div>
              <div className="w-6 h-6 rounded-full bg-neutral-200 flex items-center justify-center text-xs font-semibold">
                AS
              </div>
              <div className="w-6 h-6 rounded-full bg-neutral-200 flex items-center justify-center text-xs font-semibold">
                MK
              </div>
              <span className="text-sm text-neutral-500">+2 weitere</span>
            </div>
            <div className="text-left text-sm text-neutral-500">
              Letzte Bestellung: vor 2 Tagen
            </div>
          </div>
          <div id="station-2" className="bg-white rounded-lg shadow p-4">
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-lg font-semibold">Wohngruppe B</h2>
              <button className="text-neutral-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="1" />
                  <circle cx="12" cy="5" r="1" />
                  <circle cx="12" cy="19" r="1" />
                </svg>
              </button>
            </div>
            <div className="flex items-center space-x-2 mb-3">
              <div className="w-6 h-6 rounded-full bg-neutral-200 flex items-center justify-center text-xs font-semibold">
                EL
              </div>
              <div className="w-6 h-6 rounded-full bg-neutral-200 flex items-center justify-center text-xs font-semibold">
                RB
              </div>
            </div>
            <div className="text-left text-sm text-neutral-500">
              Letzte Bestellung: vor 1 Woche
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedisuiteStationsIllustration;
